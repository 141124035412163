<script lang="ts" context="module">
  import {
    Activations,
    Directions,
    Orientations,
    type Activation,
    type Direction,
    type Orientation,
  } from "#lib/internal/constants"
  import { reactiveContext, useActions } from "#lib/internal/helpers"
  import type { HTMLAttributes } from "svelte/elements"
  import type { Writable } from "svelte/store"

  type TabsRootProps =
    | HTMLAttributes<HTMLDivElement> & {
        value?: unknown
        activateOn?: Activation | undefined
        orientation?: Orientation | undefined
        dir?: Direction | undefined
      }

  type TabsRootContext = {
    value: TabsRootProps["value"]
    activateOn: TabsRootProps["activateOn"]
    orientation: TabsRootProps["orientation"]
    dir: TabsRootProps["dir"]
  }

  const defaults: TabsRootProps = {
    value: void 0,
    activateOn: Activations.FOCUS,
    orientation: Orientations.HORIZONTAL,
    dir: Directions.LTR,
  }

  const { getContext, setContext } = reactiveContext(defaults)
  export const getTabsRootContext = getContext
</script>

<script lang="ts">
  type $$Props = TabsRootProps
  type $$Context = TabsRootContext

  export let value: $$Props["value"] = defaults.dir
  export let dir: $$Props["dir"] = defaults.dir
  export let orientation: $$Props["orientation"] = defaults.orientation
  export let activateOn: $$Props["activateOn"] = defaults.activateOn

  const ctx: Writable<$$Context> = setContext({ value: (v) => (value = v) })

  $: ctx.update((old) => ({ ...old, value, activateOn, orientation, dir }))
</script>

<div
  {dir}
  data-orientation={orientation}
  {...$$restProps}
  use:useActions={$$restProps.use}
>
  <slot />
</div>
