<script context="module" lang="ts">
  export type ButtonGroupItemProps = BaseProps<"button"> & {
    value: NonNullable<string | number>
    id?: string | number
    disabled?: boolean
    class?: string
  }
</script>

<script lang="ts">
  import { Directions, Orientations } from "#lib/internal/constants"
  import { generateId, useActions } from "#lib/internal/helpers"
  import {
    type BaseProps,
    type UnwrapCustomEvents,
    type WrapWithCustomEvent,
  } from "#lib/internal/types"
  import { cn } from "#lib/utils"
  import { useCollection } from "radix-svelte/internal/helpers"
  import { createEventDispatcher, tick } from "svelte"
  import {
    getButtonGroupItemCollection,
    getButtonGroupRootContext,
  } from "./ButtonGroupRoot.svelte"
  import { buttonGroupItemVariants } from "./variants"

  type $$Props = ButtonGroupItemProps
  type $$Slots = { default: object }
  type $$Events = WrapWithCustomEvent<{ change: boolean }>

  let className: $$Props["class"] = ""
  export { className as class }
  export let value: $$Props["value"] = ""
  export let id: $$Props["id"] = generateId("button-group-item")
  export let disabled: $$Props["disabled"] = false
  export let use: Exclude<$$Props["use"], undefined> = []

  const dispatch = createEventDispatcher<UnwrapCustomEvents<$$Events>>()
  const ctx = getButtonGroupRootContext()
  const itemCollection = getButtonGroupItemCollection()

  $: ({ variant, deselect, name, type, orientation, dir } = $ctx)
  $: pressed = Array.isArray($ctx.value)
    ? $ctx.value.includes(value)
    : $ctx.value === value

  let wasClicked = false

  function normalize(value: unknown) {
    return typeof value === "string" ? value : JSON.stringify(value)
  }

  function getTabIndex(
    items: typeof $itemCollection,
    selected: typeof $ctx.value
  ) {
    if (!items.length) return -1

    const firstValue = items[0].dataset.value
    const selectedValue = Array.isArray(selected) ? selected[0] : selected
    const target = selectedValue ?? firstValue

    return normalize(target) === normalize(value) ? 0 : -1
  }

  function handleFocus() {
    if (
      type === "multiple" ||
      $ctx.value === null ||
      $ctx.value === undefined
    ) {
      return
    }
    if (!wasClicked) {
      handleChange()
    }
    wasClicked = false
  }

  async function toggleButtons(target: HTMLElement) {
    if ($ctx.value === null || $ctx.value === undefined) {
      target.click()
    } else {
      const otherButton = $itemCollection.find((item) => item !== target)!
      otherButton.focus()
      await tick()
      otherButton.click()
    }
  }

  function handleKeyDown(event: KeyboardEvent) {
    if (type === "multiple") return
    if (event.key === "Enter" || event.key === " ") {
      // toggle button if there are only two items
      if ($itemCollection.length === 2) {
        event.preventDefault()
        event.stopPropagation()
        toggleButtons(event.target as HTMLElement)
      }
    }
  }

  // order of operations is mouseDown -> focus -> click
  // if we don't note the state before focus, we will end up
  // toggling the value twice
  function handleMouseDown() {
    wasClicked = true
  }

  function handleClick() {
    if (pressed && deselect && type === "single") {
      $ctx.value = null
    } else {
      handleChange()
    }
  }

  function handleChange() {
    if (pressed && deselect && type === "single") {
      $ctx.value = null
    } else if ($ctx.type === "single") {
      $ctx.value = value
    } else if ($ctx.type === "multiple") {
      const values = Array.isArray($ctx.value) ? $ctx.value : []
      $ctx.value = !$ctx.value.includes(value)
        ? [...values, value]
        : values.filter((v) => v !== value)
    }
    dispatch("change", $ctx.value)
  }
</script>

<button
  class={cn(buttonGroupItemVariants({ className, variant }), {
    "first:rounded-l-md last:rounded-r-md":
      orientation === Orientations.HORIZONTAL && dir === Directions.LTR,
    "first:rounded-r-md last:rounded-l-md":
      orientation === Orientations.HORIZONTAL && dir === Directions.RTL,
    "first:rounded-t-md last:rounded-b-md":
      orientation === Orientations.VERTICAL,
  })}
  {value}
  {id}
  type="button"
  disabled={$ctx.disabled || disabled ? true : undefined}
  aria-pressed={pressed}
  data-state={pressed ? "on" : "off"}
  data-orientation={$ctx.orientation}
  tabindex={getTabIndex($itemCollection, $ctx.value)}
  on:mousedown={handleMouseDown}
  on:click={handleClick}
  on:focus={handleFocus}
  on:keydown={handleKeyDown}
  use:useActions={[
    ...(use ?? []),
    [useCollection, { collection: itemCollection }],
  ]}
  {...$$restProps}
>
  <slot />
</button>

{#if name}
  <input
    type={type === "single" ? "radio" : "checkbox"}
    hidden
    inert
    tabIndex={-1}
    {name}
    {value}
    {disabled}
  />
{/if}
