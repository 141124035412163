import {
  ACCOUNT_TYPES,
  ALLOCATION_METHODS,
  WITHHOLDING_TYPES as API_WITHHOLDING_TYPES,
  DIGITAL_ASSET_DEPOSIT_STATUSES,
  DIGITAL_ASSET_WITHDRAWAL_STATUSES,
  EN_COUNTRY_LABELS,
  IRA_DISTRIBUTION_REASONS,
  TRADING_REQUEST_TYPES,
  TaxDocumentSlugs,
  TaxWorksheetSlugs,
} from "@tastyworks/tastyworks-api"
import {
  AdditionalTradingPreferences,
  TaxFormAvailability,
} from "/@/account-management/model"
import {
  MAIL_TO_BANKING_LINK,
  SUPPORT_EMAIL,
  TaxFormGuides,
  TaxSoftwareIntegrations,
} from "/@/util"

const INVESTMENT_EXPERIENCE_OPTIONS = {
  EXTENSIVE: "Extensive",
  GOOD: "Good",
  LIMITED: "Limited",
}

const NET_WORTH_OPTIONS = {
  0: "$0 - 50K",
  50001: "$50K - 100K",
  100001: "$100K - 200K",
  200001: "$200K - 500K",
  500001: "$500K - 1M",
  1000001: "$1M - 5M",
  5000001: "$5M +",
}

export const BOOLEAN_OPTIONS = {
  false: "No",
  true: "Yes",
}

export const TOGGLE_OPTIONS = {
  false: "Off",
  true: "On",
}

export const COUNTRY_LABELS = EN_COUNTRY_LABELS

// XXX: ACH_RELATIONSHIP_STATUSES from @tastyworks/tastyworks-api. Some values
//      missing; these are all that are normally returned by the API.
const ACH_RELATIONSHIP_STATUS = {
  IDENTITY_FAIL:
    'It looks like you are having issues establishing an ACH relationship. Please contact <a href="mailto:banking@tastytrade.com">banking@tastytrade.com</a> or call 888-247-1963 for help.',
  NEEDS_BROKER_REVIEW:
    "Bank info received. We are processing your ACH link request.",
  PENDING: "Your ACH link is in progress, please wait a moment.",
  PREVIOUS_ACH_RETURNED_R10:
    'It looks like you are having issues with ACH verification. Please contact <a href="mailto:banking@tastytrade.com">banking@tastytrade.com</a> or call 888-247-1963 for help.',
  REJECTED: "Your ACH link request was rejected.",
}

const IRA_WITHDRAWAL_REASONS = {
  [IRA_DISTRIBUTION_REASONS.CONVERSION]: "Conversion",
  [IRA_DISTRIBUTION_REASONS.NORMAL]: "Normal",
  [IRA_DISTRIBUTION_REASONS.PREMATURE]: "Premature",
  [IRA_DISTRIBUTION_REASONS.DEATH]: "Death Distribution",
  [IRA_DISTRIBUTION_REASONS.EXCESS_CONTRIBUTION_REMOVAL_AFTER_TAX_DEADLINE]:
    "Excess Contribution Removal After Tax Deadline",
  [IRA_DISTRIBUTION_REASONS.EXCESS_CONTRIBUTION_REMOVAL_BEFORE_TAX_DEADLINE]:
    "Excess Contribution Removal Before Tax Deadline",
  [IRA_DISTRIBUTION_REASONS.RECHARACTERIZATION_PRIOR_YEAR]:
    "Recharacterization Prior Year",
  [IRA_DISTRIBUTION_REASONS.RECHARACTERIZATION_CURRENT_YEAR]:
    "Recharacterization Current Year",
  [IRA_DISTRIBUTION_REASONS.TRANSFER]: "Transfer",
}

const WITHHOLDING_TYPES = {
  [API_WITHHOLDING_TYPES.FIXED]: "$ Dollars",
  [API_WITHHOLDING_TYPES.PERCENT]: "% Percent",
}

const en = {
  content: {
    acatTransfers: {
      pageTitle: "Account Transfer",
      create: {
        accountIsClosed:
          "Sorry, this account is closed and cannot accept Account transfers deposits.",
        accountOwnerOnly:
          "Sorry, only account owners can manage Account transfers.",
        info: "Account Transfer allows you to electronically move part of, or your entire brokerage account, including stocks and securities, from another brokerage to tastytrade. This typically takes 7-10 business days.",
        pageTitle: "Account Transfer",
        review: {
          account: "Transferring to",
          buttonLabelBack: "Edit",
          buttonLabelSubmit: "Sign and agree to all statements",
          deliveringAccountTitle: "Delivering account title",
          deliveringAccountType: "Delivering account type",
          externalAccount: "Transferring from",
          holdings: "Positions",
          holdingsTable: {
            amount: "Amount",
            description: "Description",
            symbol: "Symbol",
          },
          info: "Please verify that your account details match. Make sure your delivering account is not restricted or frozen.",
          statementNamePrefix: "I",
          statementNameSuffix: "agree that...",
          title: "Confirm Your Transfer Details",
          transferType: "Transfer type",
        },
        reviewButton: {
          incomplete: "Complete all fields",
          invalid: "Some fields contain errors",
          noHoldings: "No holdings to transfer",
          valid: "Review Account transfer",
        },
        steps: {
          externalAccountAssets: {
            empty: "The linked account has no holdings.",
            info: "Please link your brokerage account above to proceed.",
            table: {
              amount: "Amount to transfer",
              description: "Description",
              initialAmount: "Available amount",
              symbol: "Select holding",
            },
            title: "Select Assets to Transfer",
          },
          externalAccountDetails: {
            title: "Details about the Linked Brokerage Account",
          },
          externalAccountLink: {
            info0: "Having trouble linking your brokerage accounts? Click",
            info1: "for manual instructions.",
            infoLink: "here",
            link: "Link Your Brokerage Account",
            linkDifferent: "Link a different brokerage account",
            noPositions: "You currently don't have any positions to transfer.",
            noPositionsLink: "Link to a different Brokerage",
            title: "Link your brokerage account to get started",
            titleLinked: "Linked Brokerage Account",
            unlinkCancelLabel: "Exit",
            unlinkConfirmLabel: "Yes",
            unlinkConfirmMessage:
              "Are you sure you want to disconnect your linked brokerage account?",
            unlinkConfirmTitle: "Remove Linked Brokerage Account",
          },
          externalAccountStatement: {
            info0:
              "Upload a full copy of your most recent statement from the brokerage account you wish to transfer from. If you have not yet received a statement from your other brokerage firm, tastytrade will not be able to process the Account transfer until a statement is available.",
            info1:
              "Typically, you can obtain a copy of an account statement by logging into your desired brokerage account and downloading a statement.",
            title: "Upload a Statement",
          },
          selectAccount: {
            title: "Select an Account to Transfer Into",
          },
        },
        submit: {
          close: "Close",
          failure: "Encountered an error sending your request.",
          loading: "Sending your Account request...",
          success:
            "Your Account transfer request was successfully submitted. Please allow 7-10 business days to process your application.",
          title: "Account Transfer Request",
        },
      },
      view: {
        holdings: {
          all: "Transferring all positions",
          amount: "Amount",
          description: "Description",
          symbol: "Symbol",
        },
        pageTitle: "View Scheduled Transfers",
        table: {
          accountHolder: "Account Holder",
          actions: "Actions",
          date: "Date",
          detail: {
            accountHolder: "Account Holder",
            accountType: "Account Type",
            headers: {
              holding: "holding",
              details: "details",
              quantity: "quantity",
            },
            noHoldings: "No assets to display",
            title: "Account Transfer Status",
            transferRequested: "Transfer Requested",
            transferType: "Transfer Type",
            transferTypeOptions: {
              FULL_TRANSFER: "Full Transfer",
              PARTIAL_TRANSFER_RECEIVER: "Partial Transfer",
            },
            trigger: "Details",
          },
          empty: "No Scheduled Transfers",
          estimatedArrival: "Estimated Arrival",
          estimatedArrivalShort: "Est. Arrival",
          externalAccount: "Transfer From",
          externalAccountShort: "From",
          internalAccount: "Transfer To",
          status: "Status",
          statuses: {
            actionRequired: {
              name: "Action Required",
              description: `Additional action is required to complete your transfer. Please contact our support team at ${MAIL_TO_BANKING_LINK}.`,
            },
            complete: {
              name: "Complete",
              description: "",
            },
            pending: {
              name: "Submitted",
              description:
                "You transfer was submitted to our clearing house for approval.",
            },
            pendingJointAgreement: {
              name: "Action Required",
              description: `Additional action is required to complete your transfer. Please contact our support team at ${MAIL_TO_BANKING_LINK}.`,
            },
            rejected: {
              name: "Rejected",
              description: `Your transfer was rejected. Please contact our support team at ${MAIL_TO_BANKING_LINK}.`,
            },
            underReview: {
              name: "Under Review",
              description: "Our team is reviewing your transfer.",
            },
          },
        },
      },
    },
    acceptInvitation: {
      cancel: "Cancel",
      description:
        "You've been invited to join the API team. \
        Please set a password to complete your account creation.",
      join: "Join",
      openApiConsentError: "You must accept the Open API Terms & Conditions",
      openApiConsentHeader: "PLEASE NOTE:",
      openApiConsentText:
        "Accepting invitation will grant you access to tastytrade's Open API documentation. \
        Please read and agree to the \
        <a class='text-general-hyperlink-text' href='https://assets.tastyworks.com/production/documents/open_api_terms_and_conditions.pdf'>\
        Terms & Conditions</a> before accepting.",
      openApiConsentTextButton:
        "I agree to the conditions of the document(s) above",
      openApiConsentTextErrorMessage:
        "You must agree to the Open API Terms & Conditions",
      passwordConfirmationLabel: "Confirm Password",
      passwordLabel: "Password",
      success: "You have successfully accepted the invitation.",
      title: "Join {name} Open API Team",
    },
    accountFundingJourney: {
      accountBalanceFields: {
        cash: "Cash",
        cryptoBuyingPower: "Crypto Buying Power",
        dayTradeCounter: "Day Trade Counter (Rolling 5-day period)",
        optionBuyingPower: "Option Buying Power",
        stockBuyingPower: "Stock Buying Power",
        todaysTrades: "Today’s Trades (Resets daily)",
      },
      accountBalanceHeader: {
        header: "Account Net Liq",
      },
      accountDropdown: {
        text: "selected account",
      },
      accountFundingCard: {
        title: "Ready to start trading?",
        description: "Your account is set up and ready for funding.",
      },
      accountTradingCard: {
        title: "Your Trading Journey Starts Here.",
        description:
          "Your account is now funded! Once you start trading, the Dashboard chart will display a comprehensive overview of your account performance.",
      },
      buttonText: {
        allBalances: "All Balances",
        makeADeposit: "Fund my account",
        deposit: "Deposit",
        transferAssets: "Transfer Assets",
      },
      chart: {
        emptyData: "No data available for the selected time period.",
        serverError: "Chart is currently unavailable. Please try again later.",
      },
    },
    accountOpening: {
      cardTitle: "Account Status",
      status: {
        Closed: "Closed",
        Complete: "Complete",
        "Foreign Incomplete": "Incomplete",
        Funded: "Funded",
        "Pending Joint Current": "Pending",
        "Pending Joint Other": "Pending",
        Processing: "Processing...",
      },
      tableHeading: {
        description: "What happens next?",
        name: "Account",
        status: "Status",
      },
      whatHappensNext: {
        Closed: "This account has been closed.",
        Complete:
          "Your account is ready for funding and then? You're free to start trading!",
        "Foreign Incomplete":
          "You have not finished the application for your international account yet! To finish, upload the additional documents now.",
        Funded:
          "This account is complete & funded. You're all set to trade now!",
        "Pending Joint Current":
          "Your joint account will remain pending until you submit your information and complete the application.",
        "Pending Joint Other":
          "Your joint account will remain pending until {otherApplicantName} submits their information and completes the application.",
        Processing:
          "Watch for a 'Welcome' email in 3-5 days when this account is ready for funding.",
      },
    },
    accounts: {
      buyingPower: "Option Buying Power",
      cardTitle: "Your Account Summaries",
      netLiq: "Net Liq",
      today: "Today",
    },
    accountSelector: {
      balances: "Net Liquidation / Today",
      currentAccount: "Current Account",
      placeholder: "Select an account",
    },
    achRelationships: {
      attestation:
        "tastytrade does not accept third party ACH relationships. By submitting this ACH relationship request you attest that you are the owner of the bank account.",
      buttonLabelBack: "Back",
      buttonLabelEnableMfa: "Enable 2FA",
      buttonLabelSubmit: "Submit",
      cancelLink: "Cancel This Linked Bank Account",
      currentAccount: "Current Bank Account",
      learnMoreAboutMfa: {
        label: "Learn more about two-factor authentication",
        href: "https://support.tastytrade.com/support/s/solutions/articles/43000578659",
      },
      manualTitle: "Link Your Bank",
      microDepositsInfo:
        "To verify your ACH relationship with tastytrade, please enter the amounts deposited to your bank account from Apex Clearing.",
      microDepositsNote:
        "Micro desposits can take up to 1-3 business days to appear in your bank account.",
      microDepositsTitle: "Verify Your Bank Account",
      pendingLabel: "Reviewing Bank Details",
      removeConfirmMessage:
        "Are you sure you want to disconnect your linked bank account?",
      removeConfirmTitle: "Remove Linked Bank Account",
      removeLabel: "Remove",
      requiresMfa:
        "Linking your bank account with micro-deposits requires two-factor authentication (2FA) to be enabled on your tastytrade account.",
      status: ACH_RELATIONSHIP_STATUS,
      timeFrame:
        "Please allow 1-3 business days for micro-deposits to appear at your bank. It's important to note that this deposit type is subject to $10,000 limit within 5 business day period.",
    },
    acknowledgementFormGroup: {
      hasAgreed:
        "I understand that by checking this box, I am providing a legally binding signature, just as if I were signing a paper copy.",
      hasAgreedJoint:
        "I, {firstName} {lastName}, understand that by checking this box, I am providing a legally binding signature, just as if I were signing a paper copy.",
    },
    actionRequired: {
      alertText_1:
        "There are new or updated account documents that require your acceptance. Click",
      alertText_2: " here ",
      alertText_3:
        "to review and agree to the terms of the highlighted documents. Thank you!",
    },
    addFunds: {
      cardText_1:
        "You can't trade until you put your Benjamins where your business is. Click below to make your first deposit.",
      cardText_2: "Please note:",
      cardText_3: "requires an open account.",
      cardTitle: "Add Funds to an Account",
    },
    additionalAccount: {
      button: "Open Another Account",
      cardTitle: "Want to Add a New Account?",
      content:
        "Perhaps an IRA account like Aunt Ethel has or a Cash account would be the right choice? We won't stop you! Click below to start opening another account.",
    },
    addressFields: {
      statePrompt: "Select a state...",
      countryPrompt: "Select a country...",
    },
    advancedTradingPreferences: {
      accountOwnerOnly:
        "Sorry, only account owners may enable other trading preferences.",
      // FIXME: Need to settle on what these labels should really be (some of
      //        these will result in rather large chips).
      chipLabels: {
        "Cryptocurrency Trading": "crypto",
        "Equity Offering": "clickipo",
        "Futures Trading": "futures trading",
        "Intraday Futures Margin": "intraday futures margin",
        "IRA Short Call": "shortcall",
        "Portfolio Margin": "portfolio margin",
      },
      // FIXME: Copy. These should probably be help-text.
      chipStateTitles: {
        eligible: "Eligible",
        enabled: "Enabled",
        pending: "Pending",
        "pending-joint-agreement": "Pending Joint Agreement",
        requested: "Requested",
        unavailable: "Unavailable",
        "upgrade-options-level": "Requires options-level upgrade",
        "upgrade-suitability": "Requires suitability upgrade",
      },
      descriptions: {
        "Cryptocurrency Trading": `Trading cryptocurrency with ZeroHash is available for eligible individual cash and margin accounts
                                    for clients at tastytrade. Not available for retirement accounts. Cryptocurrencies are not subject
                                    to day trading rules.`,
        "Equity Offering": `ClickIPO enables everyday individual investors to easily discover and invest in
                                      public offerings. Investors can use ClickIPO to invest in companies at the Initial
                                      Public Offering price (with no additional fees or commissions), before the shares
                                      trade on a stock exchange. ClickIPO is available for all account types. There are no
                                      restrictions on the number of accounts that may be utilized for trading.`,
        "Futures Trading": `tastytrade offers CME futures and Small Exchange products to allow you to speculate or hedge your portfolio.
                              Only margin accounts with our highest trading level, "The Works," or IRAs with "IRA The Works" enabled may apply for futures trading.
                              You will also have access to options on futures (to applicable contracts) after approval. Futures are not subject to pattern day trading
                              rules.`,
        "Intraday Futures Margin": `Trading futures contracts at a reduced margin requirement is available for eligible
                                            margin accounts approved for futures with a net liquidating value of $10,000 ($2,500
                                            for micro and Small Exchange products).
                                            Intraday futures margin is not available to individual retirement accounts.`,
        "IRA Short Call": `Trading short calls is available in any IRA account suitable for 'The Works' with a
                                   net liquidating value of $25,000.`,
        "Portfolio Margin": `Trading equities and equity option contracts at a reduced margin requirement is available for
                                     eligible margin accounts (The Works) that are approved for Portfolio Margin. The initial requirement is $175,000 and
                                     must maintain $150,000 in the account. Not available for Cash and Individual Retirement accounts.`,
      },
      eligibilityButton: {
        enable: "Enable",
        pending: "Pending",
        pendingJoint: "Pending Joint Agreement",
        submitted: "Submitted",
        unavailable: "Unavailable",
        upgrade: "Upgrade",
      },
      enableCryptocurrencyTradingPrompt: {
        cancelLabel: "Maybe later",
        confirmLabel: "Continue",
        prompt:
          "You've successfully requested Cryptocurrency Trading on this account! Continue below to allow Cryptocurrency Transfers",
        title: "Cryptocurrency Trading Enabled",
        modal: {
          title: "Trade with",
          description:
            "You've successfully enabled Cryptocurrency Trading with ZeroHash on this Account! Continue below to Allow Cryptocurrency Transfers - Deposits and Withdrawals.",
          continueButton: "Continue",
          maybeLaterButton: "Maybe later",
        },
      },
      enableIntradayFuturesMarginPrompt: {
        message:
          "You've successfully enabled intraday futures margin in this account!",
        title: "Enabling Intraday Futures Margin",
      },
      enableIraShortCallPrompt: {
        message: "Request submitted successfully!",
        title: "Submitting IRA Short Calls Request",
      },
      enablePortfolioMargin: {
        sections: {
          following: "Do any of the following apply:",
          strategies: "Please select all strategies you plan to use:",
          tradingStrategies:
            "Please select all trading strategies that will be utilized:",
        },
      },
      enablePortfolioMarginPrompt: {
        message:
          "Thanks for applying for Portfolio Margin. Please allow 3-5 business days to process your application.",
        title: "Enabling Portfolio Margin",
      },
      enableTitle: {
        [TRADING_REQUEST_TYPES.CRYPTOCURRENCY_TRADING]:
          "Enable Zero Hash Trading",
        [TRADING_REQUEST_TYPES.INTRADAY_FUTURES_MARGIN]:
          "Enable Intraday Futures Margin",
        [TRADING_REQUEST_TYPES.IRA_SHORT_CALL]:
          "Enable Short Calls / IRA The Works",
        [TRADING_REQUEST_TYPES.PORTFOLIO_MARGIN]: "Enable Portfolio Margin",
        [AdditionalTradingPreferences.FUTURES_TRADING]:
          "Enable Futures Trading",
      },
      forms: {
        descriptions: {
          "Cryptocurrency Trading":
            "Please read the documents below and confirm you agree to the terms and conditions provided therein by checking the box below to enable cryptocurrency trading in your account.",
          "Equity Offering":
            "Please read and agree to each of the documents below to enable ClickIPO in your account.",
          "Intraday Futures Margin": `Trading futures contracts at a reduced margin requirement is available for eligible
                                            margin accounts approved for futures with a net liquidating value of $10,000 ($2,500
                                            for micro and Small Exchange products).
                                            Intraday futures margin is not available to individual retirement accounts.`,
          "IRA Short Call": `Trading short calls is available in any IRA account suitable for 'The Works' with a
                                   net liquidating value of $25,000.`,
          "Portfolio Margin": "Please fill out the following questionnaire.",
        },
      },
      futuresTrading: {
        additionalQuestions: {
          description: "Please answer the following questions:",
          title: "Additional Futures Questions",
        },
        agreements: {
          documentsAgreed:
            "I have read and consent to the above agreements and disclosures and I have retained copies of each.",
          marketRisks:
            "My investment objective for this futures account is SPECULATION, and I am aware of the substantial inherent market risks.",
          reviewedAccount:
            "I have reviewed my tastytrade account profile. I attest that my personal and financial information is current and accurate, and I assume full responsibility to maintain an account profile with up-to-date information.",
          tradingIntent:
            "I am opening this futures account for the purpose of SPECULATING.",
          votingRights:
            "Any shares with voting rights may be relinquished when lent out.",
        },
        documents: {
          title: "Important Documents",
        },
        hasAgreed:
          "I understand that by checking this box, I am providing a legally binding signature, just as if I were signing a paper copy.",
        hasAgreedCftcRule155:
          "Check here to indicate you agree to the CFTC Rule 1.55 Risk Disclosure Statement",
        modal: {
          button: "Close",
          content:
            "Your request to enable futures trading on your account has been submitted! We appreciate your patience while we work through the high volume of requests. You will receive an email once your account has been enabled.",
          title: "Success!",
        },
        riskTolerance: {
          title: "Select Risk Tolerance",
        },
        submitButton: "Enable Futures Trading",
        timeHorizon: {
          title: "Select Investment Time Horizon",
        },
        tradingExperience: {
          title: "Confirm your Futures Trading Experience",
        },
      },
      pageTitle: "Trading Preferences",
      planUpgradeAlert: {
        body: {
          [TRADING_REQUEST_TYPES.IRA_SHORT_CALL]: {
            default:
              "In order to trade Short Calls with tastytrade, your account must be upgraded to the Works trading package. As a reminder, The Works is our most flexible plan*: it allows you to trade all options and futures strategies.",
          },
          [TRADING_REQUEST_TYPES.PORTFOLIO_MARGIN]: {
            default:
              "In order to trade with Portfolio Margin with tastytrade, your account must be upgraded to the Works trading package. As a reminder, The Works is our most flexible plan*: it allows you to trade all options and futures strategies.",
          },
          [TRADING_REQUEST_TYPES.INTRADAY_FUTURES_MARGIN]: {
            default:
              "In order to trade with futures with tastytrade, your account must be upgraded to the Works trading package. As a reminder, The Works is our most flexible plan*: it allows you to trade all options and futures strategies.",
          },
          [AdditionalTradingPreferences.FUTURES_TRADING]: {
            default:
              "In order to trade futures with tastytrade, your account must be upgraded to The Works trading package. As a reminder, The Works is our most flexible plan: it allows you to trade all options and futures strategies.",
            retirement:
              "In order to trade futures in your IRA with tastytrade, your account must be suitable for IRA The Works trading plan. As a reminder, IRA The Works is our most flexible plan*: it allows you to trade all options and futures strategies.",
          },
        },
        footnote: "*Along with increased flexibility comes increased risk.",
        title: {
          [TRADING_REQUEST_TYPES.CRYPTOCURRENCY_TRADING]: {
            default: "Enabling Cryptocurrency Trading - Upgrade Necessary",
          },
          [TRADING_REQUEST_TYPES.EQUITY_OFFERING]: {
            default: "Enabling ClickIPO Offering - Upgrade Necessary",
          },
          [TRADING_REQUEST_TYPES.INTRADAY_FUTURES_MARGIN]: {
            default: "Enabling Intraday Futures Margin - Upgrade Necessary",
          },
          [TRADING_REQUEST_TYPES.IRA_SHORT_CALL]: {
            default: "Enabling Short Calls / IRA The Works - Upgrade Necessary",
          },
          [TRADING_REQUEST_TYPES.PORTFOLIO_MARGIN]: {
            default: "Enabling Portfolio Margin - Upgrade Necessary",
          },
          [AdditionalTradingPreferences.FUTURES_TRADING]: {
            default: "Futures Trading requires 'The Works'",
            retirement: "Futures trading requires 'IRA The Works'",
          },
        },
        upgrade: "Upgrade",
      },
      preferenceGroup: {
        available: "Available Features",
        enabled: "Enabled Features",
        thirdParty: "Third-Party Features",
        unavailable: "Unavailable Features",
        upgrade: "Features Requiring Upgrade",
      },
      suitabilityUpgradeAlert: {
        title: {
          [TRADING_REQUEST_TYPES.IRA_SHORT_CALL]:
            "Enabling Short Calls in an IRA",
        },
      },
      titles: {
        [TRADING_REQUEST_TYPES.CRYPTOCURRENCY_TRADING]:
          "Trade Cryptocurrency with Zero Hash",
        [TRADING_REQUEST_TYPES.EQUITY_OFFERING]: "ClickIPO Offering",
        [TRADING_REQUEST_TYPES.INTRADAY_FUTURES_MARGIN]:
          "Intraday Futures Margin",
        [TRADING_REQUEST_TYPES.IRA_SHORT_CALL]: "Short Calls / IRA The Works",
        [TRADING_REQUEST_TYPES.PORTFOLIO_MARGIN]: "Portfolio Margin",
        [AdditionalTradingPreferences.FUTURES_TRADING]: "Futures",
      },
    },
    agreement: {
      statementConfirm:
        "Click here to indicate you agree to the above statements",
      statementNamePrefix: "I",
      statementNameSuffix: "agree that:",
    },

    apiAccess: {
      oauth: {
        dialog: {
          body: "Would you like to delete your current Active OAuth Grant with {name}?",
        },
        header: "OAuth Grants",
        table: {
          accountNumber: "Account Number",
          activeGrants: "Active Grants",
          date: "Grant Date",
          empty: "No Active Grants",
        },
        toastConfirm:
          "Your Open API Active Grants have been successfully updated!",
        customerGrant: {
          agree: "I Agree",
          customerProfileRequired: {
            body: "It appears you've registered with tastytrade, but haven’t completed your customer profile. Click below to do that now.",
            button: "Setup Customer Profile",
          },
        },
      },
      optIn: {
        button: "Request Opt-In",
        currentlyOptedOut: "You are currently opted out.",
        description: {
          href: "https://assets.tastyworks.com/production/documents/open_api_terms_and_conditions.pdf",
          part1:
            "Opting in to this feature will grant you access to tastytrade's Open API documentation. Please read and agree to the ",
          part2: "Terms & Conditions",
          part3: " before opting in.",
        },
        toastConfirm: "Open API Access has been successfully enabled!",
      },
      optOut: {
        button: "Request Opt-Out",
        currentlyOptedIn: "You are currently opted in.",
        description: {
          href: "https://developer.tastytrade.com/",
          part1: "To view the Open API documentation ",
          part2: "click here",
        },
        dialog: {
          body: "Would you like to Opt-Out of Open Api Access?",
          optOut: "Opt-Out",
        },
        toastConfirm: "Your Open API Access has been successfully updated!",
      },
      pageOpenAccessTitle: "Open API Access",
      pageTitle: "API",
      rejected: {
        button: "API Access disabled",
      },
    },
    autotrade: {
      allocationAmount: {
        prefix: {
          [ALLOCATION_METHODS.DOLLAR_AMOUNT]: "$",
        },
        suffix: {
          [ALLOCATION_METHODS.BUYING_POWER_PERCENTAGE]: "%",
          [ALLOCATION_METHODS.CONTRACT_QUANTITY]: "Contract(s)",
        },
      },
      createAllocation: {
        acceptDocumentsHeader:
          "By checking the box below, you agree to the following:",
        allocationUnitRequirement:
          "To allocate {amount} per unit to {account} you will need {total, number, :: currency/USD} in the account you choose for autotrade.",
        autotradeOperator: "Select a Newsletter Service Provider",
        calculateUnitAllocation:
          "x {unitValue, number, :: currency/USD}/Unit = {unitTotal, number, :: currency/USD}",
        configureAllocations: "Configure Allocations",
        invalidEligibilityParams:
          "Please select a newsletter provider, model portfolio, and valid allocation configuration.",
        linkAccount: "Link an Account",
        method: {
          [ALLOCATION_METHODS.BUYING_POWER_PERCENTAGE]:
            "You have selected an allocation of {amount}% per trade.",
          [ALLOCATION_METHODS.CONTRACT_QUANTITY]:
            "You have selected an allocation of {amount} contracts per trade.",
          [ALLOCATION_METHODS.DOLLAR_AMOUNT]:
            "You have selected an allocation of {amount, number, :: currency/USD} per trade.",
          [ALLOCATION_METHODS.UNIT_COUNT]:
            "You have selected an allocation of {amount} per trade.",
        },
        modelPortfolio: "Select a Model Portfolio",
        modelPortfolioNote:
          "Please select a model portfolio. You will only be able to set an allocation to model portfolios that you have purchased from {provider}.",
        pageTitle: "Create Allocation(s)",
        portfolioNotPaidForDisclaimer1:
          "You have not paid for {name}. Please go to the",
        portfolioNotPaidForDisclaimer2: "website and subscribe there.",
        reviewTerms: "Review Terms and Submit",
      },
      manageAllocation: {
        account: "Account",
        active: "Active",
        allocationAmount: "Allocation Amount",
        allocationMethod: "Allocation Method",
        confirmAllocationUpdate: "Your allocation for {name} is now {value}",
        edit: {
          confirm: "Ok",
          header: "Modify this Allocation",
          perTradeEdit:
            "Please select below the Allocation Method and Amount you would like to modify it to:",
        },
        inactive: "Inactive",
        method: {
          [ALLOCATION_METHODS.BUYING_POWER_PERCENTAGE]: "{amount}%/trade",
          [ALLOCATION_METHODS.CONTRACT_QUANTITY]: "{amount}Qty/trade",
          [ALLOCATION_METHODS.DOLLAR_AMOUNT]:
            "{amount, number, :: currency/USD}/trade",
          [ALLOCATION_METHODS.UNIT_COUNT]: "{amount} units",
        },
        netLiq: "Net Liq",
        nickname: "Account Nickname",
        optOut: {
          cancel: "Go Back",
          disable: "Disable Subscription",
          header: "Disable This Allocation",
        },
        pageTitle: "Manage Allocation(s)",
        portfolio: "Portfolio Name",
        status: "Status",
      },
      managePositions: {
        confirmSelfManage: "You are now self managing the position in {symbol}",
        dateOpened: "Date Opened",
        pageTitle: "Manage Autotrade Positions",
        selfManage: "Self Manage",
        selfManageButton: "I want to Self Manage this position",
        selfManaged: "Self Managed",
        symbol: "Symbol",
      },
      pageTitle: "Autotrade Allocations",
      submit: {
        close: "Exit",
        failure: "Encountered an error sending your request.",
        loading: "Sending your allocation request...",
        success:
          "Successfully submitted allocation. Head over to the Manage Allocation(s) tab to view your allocation.",
        title: "Create Allocation Request",
      },
    },
    badges: {
      amExpiration: "AM",
      nonStandard: "NS",
    },
    balances: {
      pageTitle: "Balances",
      availableFunds: {
        header: "Available Funds",
        rows: {
          netLiquidatingValue: "Net Liquidating Value",
          optionBuyingPower: "Option Buying Power",
          stockBuyingPower: "Stock Buying Power",
          startingDayPower: "Starting Day Trading Buying Power",
        },
      },
      positions: {
        header: "Positions",
        rows: {
          long: {
            bondsValue: "Long Bonds Value",
            cryptocurrencyValue: "Long Cryptocurrency Value",
            fixedIncomeValue: "Long Fixed Income Value",
            futuresOptionsValue: "Long Futures Options Value",
            futuresValue: "Long Futures Value",
            optionsValue: "Long Options Value",
            stockValue: "Long Stock Value",
            totals: "TOTALS (Long Positions)",
          },
          short: {
            bondsValue: "Short Bonds Value",
            cryptocurrencyValue: "Short Cryptocurrency Value",
            futuresOptionsValue: "Short Futures Options Value",
            futuresValue: "Short Futures Value",
            optionsValue: "Short Options Value",
            stockValue: "Short Stock Value",
            totals: "TOTALS (Short Positions)",
          },
          totals: "TOTALS (All Positions)",
        },
      },
      overall: {
        header: "Balances",
        rows: {
          pendingCash: "Pending Cash",
          cashBalance: "Cash Balance",
          maintenanceExcess: "Maintenance Excess",
        },
      },
    },
    beneficiary: {
      addAnotherBeneficiary: "Add Another Beneficiary",
      addAnotherBeneficiaryButton: "+ Add Another Beneficiary",
      addBeneficiary: "Add Beneficiary",
      beneficiariesSectionHeader: "My Beneficiaries",
      contingent: "Contingent Beneficiary",
      designationHeader: "I want to designate my Beneficiaries",
      downloadForm1: "Click ",
      downloadForm2: " to download the required form.",
      downloadFormLink: "here",
      duplicateTaxNumberErrorMessage:
        "Account beneficiaries tax numbers cannot match the account holder or be duplicated",
      pageTitle: "Beneficiaries",
      primary: "Primary Beneficiary",
      selectEdit: "Select edit below and fill out the form.",
      shareHeader: "Share %",
      skipHeader: "I want to skip this for now",
      total: "TOTAL",
      trustHeader: "I want my beneficiaries to be a trust",
    },
    castleIncident: {
      confirm: "Thanks for letting us know it was you.",
      confirmPageTitle: "Your session was confirmed!",
      confirmPasswordRecovery:
        "Thank you for confirming the origin of the login. In an effort to keep your account secure we scrambled your password and we will need you to reset it below.",
      reject:
        "We've scrambled your password due to a suspicious login attempt. Please reset your password to regain access to your account.",
    },
    chart: {
      confirmMessage: "Chart reverted to default settings",
      confirmModalTitle:
        "Reset chart settings to defaults? All of your customizations will be lost.",
      confirmModalMessage: "Are you sure you want to do this?",
    },
    common: {
      buttonCopyConfirmation: "Copied to clipboard",
      cancel: "Cancel",
      canceling: "Canceling...",
      confirmationDialog: {
        title: "Are you sure you want to proceed?",
        description: "",
        cancel: "Cancel",
        confirm: "Confirm",
      },
      contactSupport: "Contact Support",
      csvExport: "Download CSV",
      delete: "Delete",
      edit: "Edit",
      remove: "Remove",
      removing: "Removing...",
      submitted: "Submitted Successfully",
      update: "Update",
    },
    communicationPreference: {
      pageTitle: "Communication Preferences",
      receiveHow: "How would you like to receive information?",
    },
    confirm: {
      cancelLabel: "Exit",
      confirmLabel: "Yes",
    },
    confirmationDocument: {
      confirmationAlert: {
        danger: "Please Wait:",
        text: "New trade confirmations will be available by 12pm CT.",
      },
      description: "Your trade confirmations for {documentDate}",
      pageTitle: "Confirmations",
    },
    control: {
      select: {
        placeholder: "Select...",
      },
    },
    cryptocurrencyTransfers: {
      accountIsClosed:
        "Sorry, this account is closed and cannot accept cryptocurrency transfers.",
      accountOwnerOnly:
        "Sorry, only account owners can manage cryptocurrency transfers",
      assets: {
        AAVE: "Aave",
        ADA: "Cardano",
        BAT: "Basic Attention Token",
        BCH: "Bitcoin Cash",
        BTC: "Bitcoin",
        COMP: "Compound",
        DOGE: "Dogecoin",
        DOT: "Polkadot",
        EGLD: "MultiversX",
        ENJ: "Enjin Coin",
        EOS: "EOS",
        ETH: "Ethereum",
        KNC: "Kyber Network",
        LINK: "Chainlink",
        LTC: "Litecoin",
        MATIC: "Polygon",
        MKR: "Maker",
        OMG: "OMG Network",
        PAXG: "PAX Gold",
        SHIB: "SHIBA INU",
        SOL: "Solana",
        UNI: "Uniswap",
        XLM: "Stellar Lumens",
        XTZ: "Tezos",
        ZRX: "0x Protocol",
      },
      confirmYourIdentity: {
        pageTitle: "Confirm Your Identity",
      },
      currentAccountLabel: "Zero Hash Cryptocurrency Account Associated With",
      deposit: {
        accountOwnerOnly:
          "Sorry, only account owners can manage cryptocurrency transfers",
        accountRestricted:
          "You cannot perform a cryptocurrency deposit for the account {account} at this time. Please contact tastytrade Account services",
        accountSelector: {
          heading: "Select an Account to Deposit Into",
        },
        address: {
          description:
            "In order to accept cryptocurrency customers are required to Generate Wallet Address linked to their tastytrade account. Please Generate Wallet Address below.",
          heading: "Deposit Wallet Address",
        },
        addressReminder:
          "Please make sure that the address provided below is correct. All deposits sent to an incorrect address are irreversible and assets will be lost! Please triple check that you are on the official tastytrade website.",
        asset: {
          heading: "Choose desired Chain to Deposit",
          prompt: "Select asset",
        },
        comingSoon: "Cryptocurrency deposits coming soon!",
        description:
          "A Cryptocurrency Deposit is a transaction of transferring cryptocurrency from your external address (usually a wallet or an exchange) to your tastytrade account.",
        generateButton: "Generate a Deposit Wallet Address",
        generateModal: {
          cancel: "Cancel",
          confirm: "Yes",
          title: "Confirmation",
        },
        navButton: "Deposit",
        qrCode: {
          instructionsLink: "How to use QR Codes to Deposit Cryptocurrency?",
          manuallyCopy: "Manually Copy Wallet Address:",
          title: "Deposit Wallet Address",
        },
        testDepositSuggestion:
          "One of the best practices in Cryptocurrency is to make a small “Test Deposit” and ensure that deposit is successful before sending larger sums of money.",
      },
      depositHistory: {
        navButton: "Deposit History",
        tableHeader: {
          amount: "amount",
          asset: "asset",
          date: "date",
          empty: "No Deposits found",
          emptyTransactionHash: "Transaction Hash unavailable",
          fee: "FEE",
          feeAsset: "tastytrade fee ({asset})",
          feeDollars: "tastytrade fee ($)",
          from: "from",
          hide: "Hide",
          notionalGrossAmount: "GROSS AMOUNT ($)",
          notionalNetAmount: "NET AMOUNT ($)",
          returned: "Returned ",
          status: "status",
          transactionHash: "Transaction Hash",
          view: "View",
        },
        tableTitle: "Deposit History",
      },
      disclaimer: {
        header: "Disclaimer",
      },
      enable: {
        agreement: {
          agreeToTerms: "I agree to the conditions of the document(s) above*",
          confirmButton: "Enable Cryptocurrency Transfers",
          documentDescription: "tastytrade Cryptocurrency Transfers Agreement",
          heading: "Enable Cryptocurrency Transfers",
        },
        error: {
          createDigitalAssetPermission:
            "Something went wrong and we were unable to submit your request.",
          getDigitalAssetPermission:
            "Something went wrong when retrieving your permission status",
        },
        requiredActions: {
          "2fa": {
            eligibilityStatus: {
              Complete: "Enabled",
              Failed: "Failed",
              Incomplete: "Enable",
              Pending: "Pending",
            },
            header: "Two Factor Authentication",
          },
          cryptocurrencyTrading: {
            eligibilityStatus: {
              Complete: "Enabled",
              Failed: "Failed",
              Incomplete: {
                cardContent: "Enable Crypto Trading",
                confirmContent: "Enable Crypto Trading with",
              },
              Pending: "Pending",
            },
            header: "Trade Cryptocurrency with Zero Hash",
          },
          heading: "Required Actions:",
          verifyId: {
            eligibilityStatus: {
              Complete: "Confirmed",
              Failed: "Failed",
              Incomplete: "Confirm",
              Pending: "Pending",
            },
            failed:
              "Uploaded Documents couldn’t be confirmed correctly. If you are having trouble with your confirmation, please contact our customer support team at 888-247-1963.",
            header: "Confirm Your Identity",
          },
        },
        subdivision: {
          description1:
            "If your country of residence has recently changed, please go to",
          description2: "to update your address.",
          descriptionLink: "My Profile",
        },
        submitButton: "Enable Crypto Transfers with",
        title: "Enabling Cryptocurrency Transfers",
      },
      pageTitle: "Cryptocurrency Transfers",
      poweredByZeroHash: "Cryptocurrency Services Powered by Zero Hash",
      wallets: {
        add: {
          address: {
            heading: "Provide your Wallet Address",
          },
          asset: {
            heading: "Asset Type",
            prompt: "Select Asset",
          },
          confirmation: {
            assetType: "Asset Type:",
            address: "Wallet Address:",
            header: "Confirm Your Wallet",
            nickname: "Wallet Nickname:",
          },
          hasAgreed:
            "I confirm that I’ve checked the Wallet Address above and it is correct",
          nickname: {
            heading: "Set Nickname for the Wallet",
          },
          pageTitle: "Add Cryptocurrency Wallet",
          successAlert:
            "Wallet successfully added. It’s now waiting for our team to approve the use of this wallet.",
          warningModal: {
            cancel: "Cancel",
            confirm: "I Understand",
            title: "Important Cryptocurrency Information",
          },
        },
        addButton: "Add New Wallet",
        deleteButton: "Remove",
        deleteModal: {
          cancel: "Cancel",
          confirm: "Yes",
          content: "Are you sure you want to remove your linked Wallet?",
          title: "Remove Linked Wallet Address",
        },
        depositsBlocked: "Deposits Blocked",
        editName: {
          cancel: "Cancel",
          confirm: "Change",
          inputLabel: "Edit Wallet Nickname",
          title: "Trade with",
        },
        navButton: "My Wallets",
        pageTitle: "Add Cryptocurrency Wallet",
        status: {
          "Needs Manual Review": "Pending",
          "Pending Review": "Pending",
          Rejected: "Rejected",
          Reviewed: "Approved",
          "Under Review": "Pending",
        },
        table: {
          address: "WALLET ADDRESS",
          asset: "ASSET",
          empty: "No wallets found",
          nickname: "NICKNAME",
          restrictions: "RESTRICTIONS",
          status: "STATUS",
        },
        transfersBlocked: "Transfers Blocked",
        withdrawalsBlocked: "Withdrawals Blocked",
      },
      withdrawal: {
        accountOwnerOnly:
          "Sorry, only account owners can manage cryptocurrency transfers",
        accountRestricted:
          "You cannot perform a cryptocurrency withdrawal for the account {account} at this time. Please contact tastytrade Account services",
        agreementContent:
          "Please make sure that the Wallet Address provided is correct. <a href='' class='text-general-hyperlink' rel='noopener noreferrer' target='_blank'>All deposits sent to incorrect address are irreversible and assets will be lost!</a>",
        asset: {
          heading: "Select Asset you want to Withdraw",
          prompt: "Select Asset",
        },
        digitalAssetCustomerAddress: {
          heading: "Select a Wallet Address to Deposit To",
        },
        from: {
          heading: "Select an Account to Withdraw From",
        },
        hasAgreed:
          "I confirm that I’ve checked Wallet Address above and it is correct",
        navButton: "Withdrawal",
        quantity: {
          heading: "Select Amount to Withdraw",
        },
        quote: {
          account:
            "Withdrawing from Zero Hash account associated with tastytrade account:",
          amount: "In the amount of:",
          date: "Date of Withdrawal:",
          fee: "Locked Fee:",
          feeInfo:
            "The estimated network fee is an approximation and the actual network fee applied on a withdrawal may differ",
          heading: "Confirm Your Withdrawal",
          netAmount: "Total Amount:",
          pleaseRefresh:
            "Oops! Times Up. Please hit the refresh button for an updated withdrawal fee",
          refresh: "Fee Refresh:",
          wallet: "Depositing into Wallet Address:",
        },
        requestQuoteButton: "Request Fee Details",
        success:
          "Withdrawal of {netQuantity} {asset} successfully submitted. Current status is Pending.",
        table: {
          body: {
            titles: {
              asset: "Selected asset",
              assetName: "Selected asset",
            },
          },
          headers: {
            asset: "Asset",
            available: "Available amount",
            description: "Description",
            toTransfer: "Amount to transfer",
          },
          max: "MAX",
        },
      },
      withdrawalHistory: {
        navButton: "Withdrawal History",
        table: {
          asset: "ASSET",
          date: "DATE",
          empty: "No withdrawals found",
          emptyTransactionHash: "Transaction Hash unavailable",
          fee: "FEE",
          grossAmount: "GROSS AMOUNT",
          grossAmountTooltip: "The total amount requested",
          hide: "Hide",
          netAmount: "NET AMOUNT",
          netAmountTooltip: "The total amount requested (gross amount) - fees",
          notionalGrossAmount: "GROSS AMOUNT ($)",
          notionalNetAmount: "NET AMOUNT ($)",
          status: "STATUS",
          to: "TO",
          transactionHash: "TRANSACTION HASH",
          view: "View",
        },
        tableTitle: "Withdrawal History",
      },
      zerohash: {
        modal: {
          title: "Trade with",
        },
      },
    },
    customerAccountAgreements: {
      description: "Current documents for the selected account are shown below",
      header: "Documents and Agreements",
      pageTitle: "Documents",
    },
    deposits: {
      accountOwnerOnly: "Sorry, only account owners may manage deposits.",
      accountRestricted:
        "You cannot perform a cash deposit for the account {account} at this time. Please contact tastytrade Account services",
      buyingPowerGrant: {
        cancellationNotice:
          "Please note, by opting in, you <strong>WILL NOT</strong> be able to cancel this pending ACH transfer.",
        details:
          "Your ACH transfer request of {amount, number, :: currency/USD} was successfully submitted. Funds are typically available in 1-2 business days, but we're happy to grant you {grantAmount, number, :: currency/USD} in buying power in the meantime while you wait for the full amount to settle.",
        prompt:
          "Would you like us to grant you buying power in the amount of {grantAmount, number, :: currency/USD}",
        title: "Trade with Your Funds Today",
      },
      byAch: {
        depositAmount: {
          depositDeadlineInfo:
            "In order to be processed the same day, ACH deposits must be in by 2:00 pm CT.",
          depositMaxAmountInfo: `tastytrade only allows ACH transfers up to $250,000. For deposits greater than that
                amount, please fund by wire or check.`,
        },
        expiredPlaidNotice:
          "Deposits submitted with expired Plaid Bank Credentials are subject to a four-business day hold once it posts to your tastyworks account to ensure it fully clears. You can try relinking your bank to your tastyworks account to refresh your bank connection so your deposit can potentially become available for trading sooner.",
        iraConstraintsError: "Unable to retrieve IRA constraints for account",
        iraRulesAndConditions: {
          linkText: "Click here",
          unlinkedText: "for rules and conditions applicable to IRA deposits.",
        },
        linkButton: "+ Link a Bank",
        microDepositsNotice:
          "Deposits submitted via micro-deposit are subject to a four-business day hold once it posts to your tastyworks account to ensure it fully clears.",
        navHeaders: {
          depositAmount: "Deposit Amount",
          iraContribution: "Type of Deposit",
          linkedAccount: "Your Bank Account",
          selectAccount: "Select an Account",
          transactionTime: "Frequency and Date",
        },
        pageTitle: "By ACH",
        sectionHeaders: {
          depositAmount: "Enter Your Deposit Amount",
          iraContribution: "Verify the Type of Deposit You're Making",
          linkBankAccount: "Link Your Bank to Get Started",
          linkedAccount: "Your Linked Bank Account",
          selectAccount: "Select an Account to Deposit Into",
          transactionTime: "Set the Transaction Frequency and Date",
        },
        submit: {
          close: "Close",
          failure: "Oops, there was an error submitting your deposit",
          loading: "Submitting your deposit...",
          success:
            "Your deposit is processing. Head over to the deposits section check its status.",
        },
      },
      byCheck: {
        checkDetails: {
          memo: "Memo / Description",
          title: "Check Details",
          payTo: "Pay To the Order Of",
        },
        checkMailingAddress: {
          address: "Address",
          title: "Check Mailing Address",
          recipient: "To",
        },
        faq: {
          checkType: "What types of Checks do we accept?",
          depositTime: "When will my check deposit post to my account?",
          rolloverChecks: "How to Rollover a 401k or 403b?",
          title: "FAQ",
        },
        importantInformation: {
          checkName:
            "The name on your check must match the name on your tastytrade account",
          payable:
            "Checks must be made payable to tastytrade *Your Name*. Please write your account number in Memo.",
          rolloverIraPayable:
            "Checks must be made payable to Apex Clearing FBO *Your Name*. Please write your account number in Memo.",
        },
        pageTitle: "By Check",
      },
      byWire: {
        beneficiaryAccount: {
          address: "Address",
          name: "Account Name",
          number: "Account Number",
          title: "Beneficiary Account",
        },
        beneficiaryBank: {
          abaRoutingNumber: "ABA Routing Number",
          address: "Address",
          swiftRouting: "Swift BIC Routing",
          title: "Beneficiary Bank",
        },
        faq: {
          currency: "In what currency can I send the transfer?",
          expectWireDeposit: "When can I expect to see my wire deposit land?",
          fees: "Are there any fees to wire money to my tastytrade account?",
          requiredInformation:
            "Which information is required, and which is optional?",
          status: "How can I find out the status of my wire transfer?",
          title: "FAQ",
        },
        furtherCreditDescription: "For Further Credit to",
        importantInformation: {
          bankName:
            "The name on your bank account must match the name on your tasty account",
          currencyRequirement:
            "All wire transfers must be made in U.S. Dollars (USD)",
          ineligibleBanks:
            "We are unable to accept wire transfers from Western Union",
          timingNextDay:
            "Wire transfers will usually be credited to your account the next business day after being received",
        },
        instructions: {
          header: "Bank Wire Instructions",
          subHeader:
            "Provide the following information to your bank to initiate the transfer process",
        },
        pageTitle: "By Wire",
      },
      holdPeriod: {
        unverified: "5 Business Days",
        verified: "1-2 Business Days",
      },
      notification: {
        pending:
          "Deposit of {amount, number, :: currency/USD} successfully submitted. Current status is Pending",
        sendFailed:
          "Your deposit was rejected with the following message: {reason}",
      },
      oneTimeDeposit: {
        buyingPowerCancelNotice: `Verified deposits granted instant buying power cannot be canceled. If you mistakenly granted instant buying power and need to cancel it, please reach out to ${SUPPORT_EMAIL}.`,
        cancel: {
          cancel: "Go Back",
          confirm: "Cancel Deposit",
          description:
            "Are you sure you want to cancel this deposit transaction?",
          title: "Cancel Deposit",
        },
        detail: {
          accountHolder: "Account Holder",
          amount: "Deposit Amount",
          buttonText: "Details",
          cancel: "Cancel this deposit",
          cancelable:
            "Deposits may only be cancelled by 2PM CST on the day of deposit.",
          complete: "Complete",
          depositCancelled: "Deposit Cancelled",
          depositReturned: "Deposit Returned",
          depositSubmitted: "Date Submitted",
          expectedCompletion: "Expected Completion",
          externalAccount: "Transfer From",
          externalAccountType: "Account Type",
          frequency: "Deposit Frequency",
          internalAccount: "Transfer To",
          notCancelable: `Instant buying power was granted with this deposit and it may not be cancelled. If you mistakenly accepted the instant buying power and have not used it to trade, please contact ${MAIL_TO_BANKING_LINK}.`,
          title: "Bank Transfer Deposit Status",
        },
        pageTitle: "One Time Deposits",
        statuses: {
          rejected: {
            name: "Rejected",
            description: `Your transaction was rejected. Contact our support team at ${MAIL_TO_BANKING_LINK} for more info.`,
          },
          returned: {
            name: "Returned",
            description: `Your transaction was returned. Contact our support team at ${MAIL_TO_BANKING_LINK} for more info.`,
          },
          canceled: {
            name: "Canceled",
            description: `Your transaction was cancelled. Contact our support team at ${MAIL_TO_BANKING_LINK} for more info.`,
          },
          complete: {
            name: "Complete",
          },
          processing: {
            name: "Processing",
          },
        },
      },
      pageTitle: "Deposits",
      recurringDeposit: {
        cancel: {
          message:
            "Pending deposits will still be active. Future deposits will be canceled",
          title: "Are you sure you want to cancel this recurring deposit?",
        },
        emptyDeposits: "No Recurring Deposits",
        frequency: {
          Biweekly: "Biweekly Deposits",
          Daily: "Daily Deposits",
          Monthly: "Monthly Deposits",
          One_Time: "One Time Deposits",
          Weekly: "Weekly Deposits",
        },
        history: {
          header: {
            amount: "Amount",
            contributionYear: "IRA Contribution Year",
            date: "Date",
            status: "Status",
          },
        },
        pageTitle: "Recurring Deposits",
        pendingCreation:
          "Your request has been received and will be processed shortly",
        pendingFutureCreation:
          "Your request has been received and will be processed on {startDate, date}",
        recurringDepositInfo: "Recurring deposits will repeat until canceled.",
        status: {
          Active: "Active",
          Canceled: "Canceled",
          Complete: "Complete",
          "Pre-Send": "Processing",
          "Send Failed": "Send Failed",
        },
      },
      review: {
        amount: "In the amount of",
        contributionReason: {
          CONVERSION: "Conversion",
          EMPLOYER: "Employer",
          RECHARACTERIZATION: "Recharacterization",
          REGULAR: "Regular",
          ROLLOVER_60_DAY: "60 Day Rollover",
          TRANSFER: "Transfer",
        },
        contributionType: "Deposit Type",
        destination: "Depositing into tastytrade account",
        frequency: "Deposit Frequency",
        source: "From linked bank account",
        startDate: "Starting on",
        title: "Confirm your deposit",
      },
      timeline: {
        buyingPowerGranted: {
          name: "Buying Power Granted",
          nameTooltip:
            "If you made a deposit and granted yourself buying power, any remaining amount will post to your account's buying power by the listed date",
        },
        complete: {
          name: "Funds Available for Trading",
        },
        pending: {
          detail: "Evening of {date, date}",
          name: "Pending",
          nameTooltip: "When you can expect cash to leave your bank account",
        },
        submitted: {
          name: "Submitted Successfully",
        },
      },
    },
    dividendReinvesting: {
      cancelOptIn: "Opt-in Pending",
      cancelOptOut: "Cancel Opt-out request",
      optIn: "Opt-In",
      optOut: "Opt-Out",
      pageTitle: "Dividend Reinvesting",
    },
    documentUpload: {
      cardTitle: "Document Upload",
    },
    documentVerification: {
      addressDocumentVerification: {
        selectFieldLabel: "Choose an option for address document upload",
        successMessage: "Your address document has been uploaded",
        timedOut:
          "Your session has timed out. Click below to upload your documents again.",
        title: "Address Document Verification",
      },
      idDocumentVerification: {
        successMessage: "Your ID document has been uploaded",
        timedOut:
          "Your session has timed out. Click below to upload your documents again.",
        timeOutWarning: "Documents must be uploaded within 15 minutes",
        title: "ID Document Verification",
      },
      tryAgainButton: "Try Again",
    },
    emailConfirmation: {
      cancel: "Cancel",
      confirm: "OK",
      description:
        "Please enter your email address to receive a confirmation email. " +
        "Once you confirm your email address, come back to the platform, login and start trading!",
      fieldLabel: "Email",
      success: "Confirmation email sent. Please check your email.",
      title: "Your email address is unconfirmed",
    },
    emailConfirmationRedirect: {
      addFundsDescription:
        "If you've already opened an account, you're ready to fund your account.",
      additionalAccountDescription:
        "Now that we've confirmed your email, you're ready to open an account.",
      description:
        "Your account has been successfully verified and you can close this window.",
      title: "Your Email Is Confirmed!",
    },
    entityMigration: {
      backToManage: "Return to Manage Accounts",
      banner: {
        actionRequired: "Action Required",
        button: "Continue To Migrate",
        description:
          "Looks like you need to create a new login for your corporate entities.",
      },
      businessAccountsSection: {
        table: {
          button: "Continue",
          legalName: "Legal Name",
          type: "Type",
        },
        title: "Entites and Trusts",
      },
      card: {
        button: "Continue to Migrate",
        description:
          "Looks like you need to create a new login for your corporate entities.",
        title: "Action Required",
      },
      form: {
        accounts: {
          description:
            "You will be able to continue trading these accounts with your current username/email address.",
          table: {
            accountNumber: "Account Number",
            accountType: "Account Type",
            legalName: "Legal Name",
          },
          title: "These accounts will be migrated",
        },
        createNewLogin:
          "Please create a new login that will be used to access {legalName}",
        explanation:
          "You will be required to use this new login to manage {legalName} including depositing funds, withdrawing funds, or updating the information on record",
        newLogin: "New login",
        submissionError:
          "Something went wrong. If this error persists, please contact support.",
        submit: "Submit",
        title: "Create a new login",
      },
      modal: {
        title: "Next Steps",
        content:
          "To complete the migration process, please click below to sign out and confirm your new email address with our system by clicking on the link provided in the email you will receive momentarily.",
        button: "Sign Out",
      },
      title: "Attention",
    },
    exchangeAffiliations: {
      pageTitle: "Exchange Affiliations",
      status: {
        active:
          "You have linked your accounts to a Small Exchange subscription ({exchangeExternalId})",
        inactive: "You do not have any linked subscriptions.",
      },
    },
    faq: {
      label: "Frequently Asked Questions",
      title: "FAQ",
    },
    followFeed: {
      loadTrades: "New Trades Available! Click here to show them.",
    },
    forgotPassword: {
      cancel: "Cancel",
      confirm: "OK",
      description:
        "To reset your password, please enter the same email address you use to " +
        "sign in to tastytrade in the field below. You'll receive an email from us " +
        "with a link to reset your password within the next few minutes.",
      success: "Request successful. Please check your email.",
      title: "Request Password Reset",
    },
    fullyPaidSecuritiesLending: {
      confirmation:
        "Your Fully Paid Securities Lending {direction} Request has completed!",
      moreInfo: "For more information ",
      optedIn: "You are currently opted in.",
      optIn: {
        advantages: {
          reasons: {
            dailyIncome:
              "Accrue income daily, including on weekends, and get paid out monthly",
            maintainOwnership:
              "Maintain full economic ownership of your shares when lent out",
            nonDividendIncome:
              "Generate income on non-dividend paying underlyings when lent out",
          },
          title: "Advantages",
        },
        agreements: {
          bullets: {
            longShares:
              "All long shares in my account will be part of the program.",
            optingBackIn:
              "I understand if I opt out of Fully Paid Securities Lending, I will be unable to opt back in within 20 calendar days.",
            selling:
              "When I sell my shares, they will no longer be entitled to Fully Paid Securities Lending income.",
            taxAdvantages:
              "Any Fully Paid Securities Lending income may forego specific dividend tax advantages.",
            votingRights:
              "Any shares with voting rights may be relinquished when lent out.",
          },
          description:
            "Please read and agree to each of the documents below to enable Fully Paid Securities Lending in your account.",
          legallyBindingSignature:
            "I understand that by checking the box below, I am providing a legally binding  signature, just as if this were a paper copy.",
        },
        disadvantages: {
          reasons: {
            lossOfTaxAdvantages:
              "Relinquish qualified dividend tax treatment or other specific dividend tax advantages",
            lossOfVoting: "Lose voting rights on the shares lent out",
            sharesNotProtected: "Shares lent out are not covered by SIPC",
          },
          title: "Disadvantages",
        },
        moreInfo: "For more information ",
        optedInPending: "Your request to opt in should be completed shortly.",
        optedOut: "You are currently opted out.",
        pendingOptInButton: "Opt-In Pending",
        requestOptInButton: "Request Opt-In",
      },
      optOut: {
        bullets: {
          sharesRecalled:
            "I understand that by opting out from Fully Paid Securities Lending that my shares will be recalled back to my account and that I will no longer receive any lending income.",
        },
        description:
          "By opting out of Fully Paid Securities Lending, you agree to the following:",
        readConditions:
          "Please read and agree to the conditions presented above.",
        requestOptOutButton: "I want to Opt-Out",
        title: "Are you sure?",
      },
      pageTitle: "Fully Paid Securities Lending",
      readFAQs: "read our FAQ page.",
    },
    fundingPrompt: {
      title: "Ready to start trading?",
      body: "Your account is set up and ready for funding.",
      notNowButtonLabel: "Not Now",
    },
    generalInfo: {
      learnMore: "Learn More",
      note: "Note",
      pleaseNote: "Please Note",
    },
    helpCenterPanel: {
      header: "PLATFORM TOURS",
      learnCenter: "Learn Center",
      learnCenterButton: "Learn",
      newTag: "NEW",
      platformOverview: "Platform overview",
      releaseNotes: "Release Notes",
      tourButton: "New here? Take a tour!",
      tradeOptions: "How to place an options trade",
      tradeStocks: "How to place a stock trade",
    },
    igDashboard: {
      learnCenter: {
        title: "Check out our Learning Centre",
        description:
          "We have content for you whether you’ve never traded before, or are a pro and need to refresh yourself on options trading.",
        learnMoreButton: {
          label: "Start Learning",
          href: "https://www.ig.com/uk/help-and-support/us-options-and-futures/learning-centre",
        },
        hideBannerButton: "Don't show again",
      },
      demoVideoCard: {
        title: "Learn to use the platform",
        description:
          "Discover all of the features and functionalities of the US options and futures web trading platform. Get comfortable finding your way around the platform, including how to place trades, managing positions, and utilising journaling tools.",
        videos: {
          href1:
            "https://www.youtube.com/watch?v=eW0s3YWAwuw&list=PLiuPElZAFyTLN1wNcyJ5BWiOae2NhXU6Q&index=1",
          href2:
            "https://www.youtube.com/watch?v=5MYtu-kpVBA&list=PLiuPElZAFyTLN1wNcyJ5BWiOae2NhXU6Q&index=2",
          href3:
            "https://www.youtube.com/watch?v=mTSv4NHBtjU&list=PLiuPElZAFyTLN1wNcyJ5BWiOae2NhXU6Q&index=3",
        },
        images: {
          img1: "https://img.youtube.com/vi/eW0s3YWAwuw/0.jpg",
          img2: "https://img.youtube.com/vi/5MYtu-kpVBA/0.jpg",
          img3: "https://img.youtube.com/vi/mTSv4NHBtjU/0.jpg",
        },
        moreVideosLink: {
          label: "More Videos",
          href: "https://www.youtube.com/playlist?list=PLiuPElZAFyTLN1wNcyJ5BWiOae2NhXU6Q",
        },
      },
      helpResourcesCard: {
        title: "Help Resources",
        description:
          "Here are some additional resources about your US options and futures account:",
        links: {
          fundingAndAccount: {
            label: "Funding and account details",
            href: "https://www.ig.com/uk/help-and-support/us-options-and-futures-cf57eec3/funding-and-account-details-13b0af1d",
          },
          faq: {
            label: "Frequently asked questions",
            href: "https://www.ig.com/uk/help-and-support/us-options-and-futures-cf57eec3/faqs-d3eab104",
          },
          helpCentre: {
            label: "IG help and support centre",
            href: "https://www.ig.com/uk/help-and-support/us-options-and-futures-cf57eec3",
          },
        },
      },
    },
    importantReminderUpdatedAccountDocuments: {
      title: "Action Required",
    },
    institutionSearch: {
      bank: {
        emptyMessage: "No banks found",
        noInstitutionFound: "I can't find my bank",
        title: "Find Your Bank",
      },
      brokerage: {
        emptyMessage: "No brokerages found",
        noInstitutionFound: "I can't find my brokerage",
        title: "Find Your Brokerage",
      },
      searching: "Searching...",
      searchPlaceholder: "Search...",
    },
    investorProfile: {
      currentPlanSection: {
        description:
          "The current and eligible trading plans for the selected account are shown below.",
        header: "Your Current Access",
      },
      financialStandingSection: {
        description: "The financial information you provided is shown below.",
        header: "Financial Standing",
      },
      optionsLevelChange: "{level} for {accountNumber}",
      optionsLevelChangeConfirmMessage:
        "This change will result in a trading level update to {changes}. " +
        "Please be aware that changes to current trading plan may cause " +
        "certain strategies to have higher margin requirements or be " +
        "prohibited altogether.",
      optionsLevelChangeConfirmTitle: "Warning",
      pageTitle: "Investor Profile",
      tradingKnowledge: {
        description: "Your knowledge of the following products is shown below.",
        header: "Trading Knowledge",
      },
      tradingObjectiveSection: {
        header: "Trading Objectives",
        description:
          "The current trading objective for the selected account is shown below.",
      },
    },
    journal: {
      button: {
        cancel: "Cancel",
        save: "Save",
        submit: "Submit",
      },
      deleteModal: {
        text: "Are you sure you want to delete this journal entry?",
      },
      dialog: {
        addOrder: "Add Order",
        cancelledId: "cancelled-orders",
        filledId: "filled-orders",
      },
      entry: {
        addNewEntry: "+ New Entry",
      },
      form: {
        content: "Your Thoughts",
        contentPlaceholder: "Write something down...",
        editEntry: "Edit Journal Entry",
        newEntry: "New Journal Entry",
        tags: "Add Tags",
        title: "Entry Title",
        titlePlaceholder: "Entry Title...",
      },
      order: {
        now: "Last:",
        price: "Price: ${price}",
      },
      pagination: {
        empty: "No More Journal Entries",
        loadMore: "Load More",
      },
      search: {
        label: {
          title: "Filter by Label",
          placeholder: "Label",
        },
        symbol: {
          title: "Filter Journal Entries",
          placeholder: "SYM",
        },
      },
      tags: {
        addTags: "+ Tag...",
        tagList: "Tags:",
        prefix: "#",
      },
    },
    landingPagePrompt: {
      acceptButtonText: "Yes, make it my landing page",
      confirmDeclineButtonText: "Close",
      declineButtonText: "No, bring me back to trading",
      declinedPrompt: `If you would like to make the Dashboard your main landing page in the\
            future, you can enable it in`,
      initialPrompt:
        "Would you like to set the Dashboard as your landing page on launch?",
      settingsPath: "Settings > General > Landing",
    },
    live: {
      liveFilter: "Live",
      notLiveFilter: "No Live",
    },
    login: {
      copyright: {
        lineOne: "©{fullYear} tastytrade, Inc.",
        lineTwo:
          "Copyrights, logos, and trademarks are property of tastytrade, Inc. All rights reserved.",
        lineThree: "tastytrade, Inc., member",
      },
      forgotPassword: "Forgot Password?",
      missingInputs: "Please enter your credentials",
      needAnAccount: "Need a tastytrade account?",
      networkIssue: "Network Issue",
      password: "Password",
      rememberMe: "Remember Me (today)",
      rememberMeTooltip:
        "Session will be active for today <br /> even if the browser is closed.",
      signupNow: "Sign up now!",
      username: "Username",
    },
    mobileAppDownload: {
      content:
        "We noticed you are on a mobile device. Head over to the App store to download the tastytrade mobile app to access our trading platform.",
      title: "Trading with tastytrade",
      iosUrl:
        "https://apps.apple.com/us/app/tastytrade-invest-trade/id1276909854",
      androidUrl:
        "https://play.google.com/store/apps/details?id=com.ig.tastyworks.app&hl=en_US&gl=US&pli=1",
    },
    mobileAppDownloadIg: {
      content:
        "We noticed you are on a mobile device. Head over to the App store to download the IG US Options and Futures mobile app to access our trading platform.",
      title: "Trading with IG US Options and Futures",
      iosUrl:
        "https://apps.apple.com/uk/app/ig-us-options-futures/id6474629769",
      androidUrl:
        "https://play.google.com/store/apps/details?id=com.tastytrade.mobile.ig",
    },
    moneyDashboard: {
      depositsLatest: "Latest deposits",
      depositsViewAll: "View all",
      makeDeposit: "Add funds",
      makeWithdrawal: "New withdrawal",
      pendingTransactions: "Money transactions",
      withdrawalsLatest: "Latest withdrawals",
      withdrawalsViewAll: "View all",
    },
    navigation: {
      accountManagement: {
        accounts: "My Accounts",
        money: "My Money",
        moneyDashboard: "Dashboard",
        profile: "My Profile",
      },
    },
    oneTimePassword: {
      invalid: "The code you have entered is incorrect",
      loginLocked:
        "Your login has been temporarily locked for due to excessive failed attempts.",
      prompt: {
        authenticator:
          "Please open the Authenticator App on your mobile device and enter the six digit code provided for tastytrade:",
        sms: "We sent a security code to your mobile device to verify your identity. Please enter the security code below:",
      },
      resend: {
        button: "Resend",
        done: "Code resent!",
        prompt: "Didn't get the code?",
        wait: "Resending...",
      },
      title: "We need to verify your identity.",
    },
    optionsTradingTour: {
      complteOptionsTour:
        "You've completed the options trading tour. You can retake it later by clicking on 'Help'.",
      demoVideo: "Demo Video",
      sellingOptions:
        "Here's a step-by-step walkthrough for trading options on the platform.",
      title: "New to trading options?",
    },
    orderEntry: {
      checkFutures: "Check Futures Eligibility",
      cryptoCaption: "Enable cryptocurrency trading with ",
      cryptoUnavailable: "Cryptocurrency trading is not available",
      enableCrypto: "Enable Crypto Trading",
      futuresCaption:
        "Looking to trade futures? See if you're eligible to apply.",
      futuresLearnMoreHref:
        "https://support.tastytrade.com/support/s/solutions/articles/43000435201",
      tradeWith: "Trade with ",
      unexpectedMessage:
        "If this message is unexpected, make sure the correct account is selected.",
      util: {
        notTradable: "underlying ({security}) is not tradable",
      },
    },
    overview: {
      columnHeader: {
        amount: "Amount",
        contributionYear: "Contribution Year",
        date: "Date Submitted",
        depositAmount: "Deposit Amount",
        depositSubmitted: "Date Submitted",
        from: "From",
        status: "Status",
        to: "To",
      },
      emptyMessage: {
        oneTimeDeposit: "No latest deposits",
        pendingTransaction: "No pending transactions",
        withdrawlTransaction: "No latest withdrawals",
      },
    },
    passwordChangedRedirect: {
      anchorLabel: "Back to Login",
      description:
        "You have also been sent a confirmation email that your password has been changed.",
      pageTitle: "Password Successfully Changed",
    },
    passwordResetRedirect: {
      pageTitle: "Reset Password",
    },
    positions: {
      allPositionsFilter: "All Positions",
      assetType: {
        buttons: {
          closed: "Closed",
          crypto: "Crypto",
          fixedIncome: "Fixed income",
          futures: "Futures",
          options: "Options",
          stocks: "Stocks",
          working: "Working",
        },
      },
      capitalRequirements: "Cap Req",
      collapseTooltip: "Collapse Positions",
      columnSettings: "Column Settings",
      expandTooltip: "Expand Positions",
      noPositionsFilter: "None Selected",
      noPositionsInfo: "No positions based on current filter selection",
    },
    privateDocument: {
      heading: {
        date: "Date",
        description: "Description",
        file: "File",
        type: "Type",
      },
    },
    profile: {
      affiliationInfo: "Affiliation Information",
      contactInfo: "Contact Information",
      employmentInfo: "Employment Information",
      fullName: "Full Name",
      homeAddress: "Home Address",
      mailingAddress: "Mailing Address",
      pageTitle: "Personal Information",
      personalInfo: "Personal Information",
      trustedContact: "Trusted Contact",
    },
    promoCode: {
      inputLabel:
        "Do you have promotional code? Enter the promotional code below",
      placeholder: "Enter promo code...",
      usedLabel: "You used promotional code: ",
    },
    reconfirmation: {
      pageTitle: "Your Email is Updated!",
    },
    redirect: {
      errorTitle: "Uh oh!",
      errorToken: "This token is invalid or has expired",
    },
    referrals: {
      buttonText: "View Referral Link",
      cardText_1:
        "Refer your friends, neighbors, family, coworkers, trainers, people on the bus...",
      cardText_2:
        "Click “View Referral Link” to generate a referral link you can send to your people. To learn more about our referral program, ",
      cardTitle: "Referrals",
      clickHere: "click here",
      clickHereUrl: "https://tastytrade.com/referral",
      copyText: "Copy this link and send it to your people (all of them!)",
      inputLabel: "Did someone refer you? Enter the referral code below",
      learnMore: "Learn More",
      placeholder: "Enter referral code...",
      redeemableCredits: "Your redeemable credits",
      redeemableLabel: "Referrals must be funded.",
      rulesLink: "Learn More",
      rulesUrl: "https://tastytrade.com/referral#rules",
      submitButton: "Submit",
      usedLabel: "You used referral code: ",
    },
    requiredAgreementDocuments: {
      agreementButtonLabel: "Yes",
      agreementPrompt:
        "Have you received, read, and understood each of the documents above, and do you agree to all provisions in each document?",
      documentsSubHeader: "Document(s)",
      header: "Action Required",
      preamble: `The following documents are new or updated and require your
            acceptance. tastytrade reserves the right to, in its sole discretion,
            to terminate your use of certain account features and/or trade privileges
            without prior notice if you do not agree to the following documents:`,
    },
    resources: {
      commissionsFees: "Commissions & Fees",
      commissionsFeesUrl: "https://tastytrade.com/commissions-and-fees/",
      disclosures: "Disclosures",
      disclosuresUrl: "https://tastytrade.com/disclosures/",
      finraBrokerCheck: "FINRA BrokerCheck",
      finraBrokerCheckUrl: "https://brokercheck.finra.org/",
      formsAgreements: "Forms & Agreements",
      formsAgreementsUrl: "https://tastytrade.com/forms-and-agreements/",
      helpCenter: "Help Center",
      helpCenterUrl: "https://support.tastytrade.com/support/s/",
      title: "Resources",
      tradingPlatforms: "Trading Platforms",
      tradingPlatformsUrl: "https://tastytrade.com/trading-platforms/",
    },
    riskMonitoring: {
      expiration: {
        agreeLabel: "I agree to the conditions of the document(s) above",
        buttonLabel: "I'm Monitoring",
        buttonLabelEnabled: "Already Monitoring",
        disclaimerTitle: "Submitting Expiration Monitoring Request",
        title: "Expiration Risk Monitoring",
      },
      pageTitle: "Risk Monitoring",
      pdtReset: {
        button: {
          eligible: "Reset PDT Status",
          notPdt: "Not Classified PDT",
          pending: "Pending",
          pendingJointAgreement: "Pending Joint Agreement",
          unavailable: "Unavailable",
        },
        modal: {
          cancel: "No",
          closingOnlyTitle: "Remove closing only?",
          confirm: "Yes",
          importantTitle: "Important!",
        },
        sectionTitle: "Reset Pattern Day Trader (PDT) Status",
        unavailableContent:
          "This account is classified as a Pattern Day Trader (PDT), and you have already used your 1 PDT status reset on {resetDate}. Customers are only allowed 1 PDT status reset over the lifetime of their margin account(s).",
      },
    },
    security: {
      authenticatorSection: {
        description:
          "When a sensitive action is performed, the authenticator app will provide a security code to be input into the prompt to verify your identity.",
        enabledLabel: "Enabled",
        enableLabel: "Enable 2FA",
        header: "Authenticator App",
        switchToAuthenticatorLabel: "Switch to App",
        update: "Transitioning to a new authenticator? ",
      },
      disclaimer: {
        button: "Enable Two Factor Authentication",
        description:
          "tastytrade strongly encourages all customers to enable two-factor authentication (2FA). This adds another layer of security when performing sensitive actions.",
        link: "Learn More about two-factor authentication",
        title: "It's time to enable Two-Factor Authentication!",
      },
      downloadAuthenticator: {
        instructions:
          "To enable two-factor authentication, download an authenticator app to your mobile device and create an account. We recommend <a rel='noopener noreferrer' class='text-general-hyperlink' href='https://authy.com/' target='_blank'>Authy</a>, Google Authenticator (<a rel='noopener noreferrer' class='text-general-hyperlink' href='https://apps.apple.com/us/app/google-authenticator/id388497605' target='_blank'>iOS</a>, <a rel='noopener noreferrer' class='text-general-hyperlink' href='https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2' target='_blank'>Android</a>), <a rel='noopener noreferrer' class='text-general-hyperlink' href='https://www.microsoft.com/en-us/security/mobile-authenticator-app' target='_blank'>Microsoft Authenticator</a>, or <a rel='noopener noreferrer' class='text-general-hyperlink' href='https://2fas.com/' target='_blank'>2FAS Authenticator</a>. Please do not use a QR Reader/Scanner app when authenticating your account. ",
        learnMore:
          "https://support.tastytrade.com/support/s/solutions/articles/43000578659",
        step: "Download An Authenticator",
        task: "You will only be required to use two-factor authentication for the following actions: ",
      },
      enabledSuccess: {
        description:
          "Two-factor SMS authentication is now enabled. This added layer of protection will help keep your accounts secure. You will use your mobile device to retrieve a 6-digit code when performing the following account-related tasks: ",
        descriptionAuthenticatorEnabled:
          "Two-factor authentication is now enabled. This added layer of protection will help keep your accounts secure.",
        descriptionAuthenticatorUsage:
          "Keep your Authenticator Application installed on your mobile device. You will use your Authenticator app to retrieve a 6-digit code when performing the following account-related tasks:",
        reminder:
          "You can access your security settings at any time from your tastytrade profile.",
        stepAuthenticator: "Two-Factor Authenticator Enabled!",
        stepSms: "Two-Factor SMS Enabled!",
        submit: "I Understand",
        tasks: {
          changeEmail: "Changing your email address",
          establishAch: "Establishing a new ACH relationship",
          resetPassword: "Resetting or changing your password",
          withdrawAch:
            "Initiating a withdrawal from your tastytrade account via ACH, check, or wire",
        },
      },
      mobileConfirmation: {
        contentTitle: "Do You Want To Enable Two-Factor Authentication?",
        description:
          "This will add an extra layer of security to all of your tastytrade accounts. We will send you a six-digit verification code via text message to enable two-factor authentication.",
        phone: "Current mobile phone number: ",
        step: "SMS Authentication",
        submit: "Enable Two-Factor Authentication",
        submitting: "Submitting...",
      },
      myUsername: {
        confirmEmailDescription:
          "We really want to get this right! Please check your inbox for an email we've just sent and click the included link to complete the process.",
        passwordRequired: "Required for updating your email address.",
        tooltip: "Your username cannot be changed at this time",
      },
      pageTitle: "Security",
      pageTitleEnabling: "Enabling Two-Factor Authentication",
      readyToAuthenticate: {
        descriptionDownload:
          "Once you've successfully downloaded your authenticator app to your mobile device, you're ready to authenticate with tastytrade. Please open the authenticator app and have it ready to use before clicking `Ready To Authenticate` below.",
        descriptionVerificationCode:
          "In order to receive the verification code required to complete the authentication process, you must use an authenticator app.",
        help: "If you are having trouble with your verification code, contact our customer support team at 888-247-1963",
        step: "Secure Your Account",
        submit: "Ready To Authenticate",
      },
      resetPassword: {
        onSuccess:
          "You have been logged out due to a password change. Please log in again with the new credentials.",
      },
      securityQuestion: {
        customQuestion: {
          editMode: "Type Your Security Question",
          readOnly: "Your Security Question",
        },
        header: "My Security Question",
      },
      smsSection: {
        description:
          "A security code will be sent to your mobile device when you perform a restricted action. You will then need to input the security code to the prompt to verify your identity.",
        enabledLabel: "Enabled",
        enableLabel: "Enable SMS",
        header: "SMS Authentication",
        switchToSmsLabel: "Switch to SMS",
      },
      switchToSms: {
        alert:
          "Only one method of two factor of authentication may be enabled at the same time. By enabling SMS authentication you will not be able to use your current Authenticator app to provide a security code until it is re-activated.",
        description:
          "You will need access to your mobile device in order to proceed.",
        header: "Switching to SMS Authentication?",
        pageTitle: "Enabling Two-Factor Authenticator",
        passwordLabel: "Current Password",
        passwordPlaceholder: "Password",
      },
      twoFactorMethod: {
        description:
          "Adding two factor authentication to your account offers an additional layer of security when performing sensitive actions.",
        header: "Two Factor Authentication",
        reminder:
          "Please enable Two Factor Authentication below to further secure your accounts.",
      },
      updateMobilePhone: {
        currentMobilePhone: "Current mobile phone number:",
        description:
          "You will need access to your old device and new mobile device in order to proceed. Don't have access to the old device? Contact our support team at ",
        link: "Update this Setting",
        pageTitle: "Enabling Two-Factor Authenticator",
        passwordPlaceholder: "Password",
        smsDescription: "Did you change your Mobile Phone Number? ",
        subHeader: "Need to update your Mobile Number?",
      },
      validateAuthenticator: {
        description:
          "Using your authenticator app, scan the QR code below to sync the account and then enter the provided verification code from the authenticator app into the field below. In order to receive the verification code required to complete the authentication process, you must use an authenticator app to scan the QR code.",
        help: "If you are having trouble with your verification code, contact our customer support team at 888-247-1963",
        step: "Secure Your Account",
        submit: {
          modified: "Secure Account",
          unmodified: "Scan QR Code In Authenticator",
        },
        unsupportedBrowser: "Your browser does not support the canvas tag.",
      },
      validateNumber: {
        incorrectCode:
          "Oops! That security code does not appear to be correct. Please try again in moment.",
        step: "Verify Your Phone Number",
        submit: "Verify Code",
        submitting: "Submitting...",
      },
    },
    signature: {
      clear: "Clear",
      info: "Please sign below",
      label: "Signature",
    },
    statementDocument: {
      description: "Your account statement for {start} - {end}",
      pageTitle: "Statements",
    },
    stockTradingTour: {
      completeStockTour:
        "You've completed the stock trading tour. You can retake it later by clicking on 'Help'.",
      demoVideo: "Demo Video",
      sellingStock:
        "Here's a step-by-step walkthrough for buying and selling stock.",
      title: "New to Stock Trading?",
    },
    supportingDocuments: {
      documentsRequestedInstructions:
        "tastytrade has requested that you securely upload additional documents " +
        "related to your account(s). Please use the links provided below to upload " +
        "the requested documents.",
      fileUploadedLabel: "Uploaded",
      fileUploadLabel: "Upload",
      noDocumentsRequested:
        "No documents related to your account(s) are requested at this time.",
      slugs: {
        acat_delivering_account_statement: {
          buttonLabel: "a Recent Account Statement",
        },
        acat_request_form: {
          buttonLabel: "ACAT Request Form",
        },
        address_document: {
          buttonLabel: "an Address Document",
        },
        beneficial_owner_1_id_document: {
          buttonLabel: "Beneficial Owner ID Document",
        },
        beneficial_owner_2_id_document: {
          buttonLabel: "2nd Beneficial Owner ID Document",
        },
        beneficial_owner_3_id_document: {
          buttonLabel: "3rd Beneficial Owner ID Document",
        },
        cftc_155_3_authorization: {
          buttonLabel: "CFTC 155.3 Authorization",
        },
        cme_professional_agreement: {
          buttonLabel: "CME Professional Agreement",
        },
        entity_beneficial_owner_1_form: {
          buttonLabel: "Entity Beneficial Owner Form",
        },
        entity_beneficial_owner_2_form: {
          buttonLabel: "2nd Entity Beneficial Owner Form",
        },
        entity_beneficial_owner_3_form: {
          buttonLabel: "3rd Entity Beneficial Owner Form",
        },
        id_document: {
          buttonLabel: "ID Document",
        },
        individual_beneficial_owner_1_form: {
          buttonLabel: "Individual Beneficial Owner Form",
        },
        individual_beneficial_owner_2_form: {
          buttonLabel: "2nd Individual Beneficial Owner Form",
        },
        individual_beneficial_owner_3_form: {
          buttonLabel: "3rd Individual Beneficial Owner Form",
        },
        ira_deposit_slip: {
          buttonLabel: "IRA Deposit Slip",
        },
        ira_distribution_request: {
          buttonLabel: "IRA Distribution Request",
        },
        nasdaq_professional_agreement: {
          buttonLabel: "NASDAQ Professional Agreement",
        },
        nyse_professional_addendum: {
          buttonLabel: "NYSE Professional Addendum",
        },
        nyse_professional_agreement: {
          buttonLabel: "NYSE Professional Agreement",
        },
        opra_professional_agreement: {
          buttonLabel: "OPRA Professional Agreement",
        },
        roth_ira_conversion_form: {
          buttonLabel: "Roth IRA Conversion Form",
        },
        roth_ira_distribution_request: {
          buttonLabel: "Roth IRA Distribution Request",
        },
        selfie_with_id: {
          buttonLabel: "Selfie with ID",
        },
        ssn_document: {
          buttonLabel: "SSN Document",
        },
        w_8ben: {
          buttonLabel: "W-8BEN",
        },
        w_9: {
          buttonLabel: "W-9",
        },
      },
    },
    symbolSearch: {
      filters: {
        ALL: "All",
        CRYPTOCURRENCIES: "Crypto",
        EQUITIES: "Stocks",
        ETFS: "ETF",
        FUTURES: "Futures",
        LIQUIDITY: "Liquidity",
      },
      buttonNames: {
        ALL: "All",
        CRYPTOCURRENCIES: "Crypto",
        EQUITIES: "Stocks",
        ETFS: "ETFs",
        FUTURES: "Futures",
        LIQUIDITY: "Liq Pools (New)",
      },
    },
    taxCenter: {
      calendar: {
        navButton: "Full Tax Calendar",
        availability: {
          [TaxFormAvailability.ONLINE]: "Available Online",
          [TaxFormAvailability.MAILED]: "Mailed",
        },
      },
      disclaimer: {
        content:
          "The taxpayer is ultimately responsible for the accuracy and completeness of the tax returns filed on their behalf. Please consult your tax advisor for additional information.",
        header: "Disclaimer",
      },
      documents: {
        accountAndYear: "Select an Account and Year",
        downloadButton: "Download",
        navButton: "Tax Documents",
        noDocumentsFound:
          "You do not have any tax documents yet for this year.",
        retirementAccountNote:
          "Keep in mind that retirement accounts do not generate a Consolidated 1099 for trades, dividends, etc. If you did not make any contributions or distributions for {year}, you will not receive any tax documents for this account.",
        taxDocuments: {
          header: "Tax Documents",
          originalOn: "Original on {date}",
          types: {
            [TaxDocumentSlugs.IRS_1042_S_TAX]: "Form 1042-S",
            [TaxDocumentSlugs.IRS_1099_TAX]: "Consolidated Form 1099",
            [TaxDocumentSlugs.IRS_1099B_CRYPTOCURRENCIES_TAX]:
              "Form 1099-B Cryptocurrencies",
            [TaxDocumentSlugs.IRS_1099B_TAX]: "Form 1099-B Futures",
            [TaxDocumentSlugs.IRS_1099R_TAX]: "Form 1099-R (Distributions)",
            [TaxDocumentSlugs.IRS_5498_TAX]: "Form 5498 (Contributions)",
          },
          updatedOn: "Updated on {date}",
        },
        taxWorksheets: {
          downloadButton: "Download",
          footer: {
            description: "If you don't see the form you are looking for:",
            formAvailability_1: "Check the ",
            formAvailability_2: " for form availability dates",
            reportingRequirements_1: "See if your earnings meet the ",
            reportingRequirements_2: "reporting requirements",
            reportingRequirements_url:
              "https://support.tastyworks.com/support/solutions/articles/43000435208",
          },
          header: "Tax Worksheets",
          notFoundModal: {
            closeButton: "Close",
            content:
              "Tax worksheets will be available for download at the same time 1099 Consolidated documents are available. Please check the calendar below for all Tax Document delivery dates.",
            header: "Please Note",
          },
          types: {
            [TaxWorksheetSlugs.GAIN_LOSS_TAX_WORKSHEET]: "Tax Worksheet",
            [TaxWorksheetSlugs.NO_WASH_SALES_TAX_WORKSHEET]:
              "Tax Worksheet (w/o Wash Sales)",
            [TaxWorksheetSlugs.WITH_WASH_SALES_TAX_WORKSHEET]:
              "Tax Worksheet (w/ Wash Sales)",
          },
        },
        yearToDate: {
          header: "Year-to-Date Data Export",
          yearToDownload: "{year} Year-to-Date Data Export",
        },
      },
      guides: {
        button: "View",
        pageDescription:
          "We know taxes can be daunting, so we designed a series of guides that are easy to read and without any tax jargon. Chances are, you can find answers to most of your questions or concerns in this guide, but we're always here to help!",
        [TaxFormGuides.CONSOLIDATED_1099_GUIDE]: {
          header: "Consolidated 1099 Guide",
        },
        [TaxFormGuides.IRA_TAX_FORM_GUIDE]: {
          header: "IRA Tax Form Guide",
        },
        [TaxFormGuides.INTERNATIONAL_ACCOUNTS_TAXES_GUIDE]: {
          header: "International Accounts Taxes Guide",
        },
        url: {
          consolidated1099Guide:
            "https://drive.google.com/file/d/1pLfVaIW8B0vhvwW9P2qjjA3QVGd3F6FV/view",
          iraTaxFormGuide:
            "https://drive.google.com/file/d/1pkeGjJyMlQcz6a354UfaslJRTIv3JzAe/view?pli=1",
          internationalAccountsTaxesGuide:
            "https://drive.google.com/file/d/1C7XhkKZ0svfEiaKQcthrM5IkXEs4ZOQn/view",
        },
      },
      header: "Tax Center",
      integration: {
        navButton: "Tax Software Data Integration",
        pageDescription: {
          helpCenterHref:
            "https://support.tastyworks.com/support/solutions/43000224175",
          part_1:
            "Transaction data for tax software will be available Feb 21. Please follow the instructions below to learn how to import your tastytrade data to prepare your taxes. Before you get started, make sure that your software is up-to-date. Please refer to our ",
          part_2: "Help Center Tax Center",
          part_3: " section for all additional information.",
        },
        [TaxSoftwareIntegrations.TURBO_TAX]: {
          header: "Intuit Turbotax",
          description:
            "tastytrade supports full data integration with TurboTax.",
          linkText: "Click here for details.",
          href: "https://support.tastyworks.com/support/solutions/articles/43000435239-importing-data-to-turbotax",
        },
        [TaxSoftwareIntegrations.HR_BLOCK]: {
          header: "H&R Block",
          description:
            "tastytrade supports full data integration with H&R Block.",
          linkText: "Click here for details.",
          href: "https://support.tastyworks.com/support/solutions/articles/43000435367-importing-data-to-h-r-block-tax-software",
        },
        [TaxSoftwareIntegrations.TAX_ACT]: {
          header: "TaxAct",
          description: "tastytrade supports full data integration with TaxAct.",
          linkText: "Click here for details.",
          href: "https://support.tastyworks.com/support/solutions/articles/43000435350-importing-data-to-taxact",
        },
        [TaxSoftwareIntegrations.TRADE_LOG]: {
          header: "TradeLog",
          description:
            "Limited integration with TradeLog is available through our clearing firm, Apex Clearing.",
          linkText: "Read More.",
          href: "https://support.tastyworks.com/support/solutions/articles/43000435341-importing-data-to-tradelog-creating-apex-credentials-",
        },
      },
      navigation: {
        calendar: "Tax Calendar",
        documents: "Tax Documents",
        guides: "Tax Form Guides",
        integration: "Tax Software Data Integration",
      },
      sidebar: {
        datesToKnow: {
          header: "Tax Dates to Know",
          none: "No upcoming dates to know",
        },
        taxFormGuides: {
          header: "Download Tax Form Guides",
          internationalGuide: "International Tax Form Guide",
        },
        taxHelpCenter: {
          expectedForms: "Which tax forms can I expect?",
          header: "Tax Help Center",
          internationalFAQs: "FAQ for International Accounts",
          iraTaxRules: "Roth & Traditional IRA Tax Rules",
        },
        taxHelpCenterButton: "Visit Tax Help Center",
      },
      viewCalendarButton: "View Full Calendar",
    },
    thinkific: {
      bannerTitle: "Not Sure Where To Start?",
      button: "Get Started",
      description:
        "Head over to tastytrade courses. Our team will show you how to use our platform, go over the basics of trading, and much more.",
      disclaimer1:
        "By visiting this site, certain personal information will be sent to Thinkific, who manages these courses. Please see",
      disclaimer2:
        "for further details concerning the collection and processing of your personal information.",
      disclaimerLinkText: "Thinkific's Privacy Policy",
      href: "https://courses.tastytrade.com",
    },
    timePeriod: {
      OneMonth: "Last 30 Days",
      OneWeek: "Last 7 Days",
      SixMonths: "Last 6 Months",
      ThreeMonths: "Last 3 Months",
      YearToDate: "Year to Date",
    },
    tourToastAlert: {
      button: {
        exploreOtherTours: "Explore Other Tours",
        takeTour: "Take a Tour",
      },
      demoVideo: "Watch Demo Video",
      title: "PLATFORM TOURS",
    },
    trading: {
      autotrade_1: "A",
      autotrade_2: "AUTO",
      chatButton: "Chat",
      connectionStatus: {
        accounts: {
          full: "Connected",
          none: "Not Connected",
        },
        market: {
          label: "Market",
        },
        quotes: {
          full: "Quotes",
          none: "Quotes N/A",
          partial: "Delayed Quotes",
        },
        summaryLabel: "Status",
      },
      curveSliderView: {
        itmLabel: "ITM",
      },
      helpButton: "Help",
      loading: {
        accounts: "Loading accounts",
        accountsLoaded: "Accounts loaded",
        initial: "Initializing trading session",
        loggingOut: "Logging out",
        session: "Loading session",
        streamerLoaded: "Streamer loaded",
      },
      logoutButton: "Sign Out",
      notification: {
        nativeWarningPrefix: "Warning:",
        preMarketCancelWarning: {
          title: "Pre-Market Cancel",
          message:
            "Your cancel order request will likely be rejected by the exchange",
        },
        unableToCancelOrderWarning: {
          title: "Unable to Cancel Order",
        },
      },
      settingsButton: "Settings",
      tradeTicket: {
        button: "Review & Send",
        orderValidationError: "Validation Error",
        orderEntrySummary: {
          costsAndCharges: {
            modal: {
              calculations: {
                part_1:
                  "These numbers are based on your order levels, using the calculations on our ",
                part_2: "commissions & fees",
                part_3:
                  " page. The final numbers may vary due to price change between order and execution.",
              },
              closingCosts: {
                header: "Estimated closing costs",
              },
              detail: {
                title: {
                  clearingFee: "Clearing Fee",
                  commission: "Commission",
                  proprietaryFee: "Proprietary Fees",
                  regulatoryExchangeFee: "Regulatory & Exchange Fees",
                },
              },
              header: "Costs & charges",
              immediateCosts: {
                header: "Immediate costs",
              },
              marginInterestRate: {
                header: "Margin interest rate",
                view: "View rates",
                tooltipDescription:
                  "You are charged margin interest based on how much you borrow to open and maintain your trades.",
              },
            },
          },
        },
      },
      underlyingQuote: {
        tradingHalted: "HALTED",
      },
    },
    transfers: {
      create: {
        accountsStatusChip: {
          eligible: "Accounts are eligible for internal cash transfer",
          ineligible: "Accounts are not eligible for internal cash transfer",
          sameAccount: "Cannot transfer to the same account",
          unset: "Select accounts",
          unsetDestination: "Please select an account to transfer into",
          unsetSource: "Please select an account to transfer from",
          unsupported: "Unsupported account type",
          unsupportedHelp:
            "Only tranfers between Individual accounts are currently supported",
        },
        available: "Available to Withdraw",
        cancelTransferRejected:
          "Your request cannot be processed at this time. Please contact banking@tastytrade.com.",
        info: "Transfer funds between your accounts.",
        pageTitle: "Internal Transfer",
        review: {
          distributionConversionTitle: "IRA Distribution Conversion Agreement",
          fields: {
            accountNumber: "Withdrawing from tastytrade Account:",
            amount: "In the amount of:",
            contribution: "IRA Contribution Type:",
            destinationAccountNumber: "Depositing into tastytrade Account:",
            distribution: "IRA Distribution Type:",
            federalWithholding: "Less federal tax withholding",
            frequency: "Deposit Frequency:",
            stateWithholding: "Less state tax withholding",
          },
          info: "Please review and submit your internal transfer request.",
          title: "Confirm Your Internal Transfer",
        },
        steps: {
          accountNumber: {
            title: "Select an Account to Transfer From",
          },
          amount: {
            title: "Enter Your Transfer Amount",
          },
          contributionReason: {
            title: "Select Contribution Reason for {account}",
          },
          destinationAccountNumber: {
            title: "Select an Account to Transfer Into",
          },
          distributionReason: {
            title: "Select Distribution Reason for {account}",
          },
          frequency: {
            title: "Set the Transaction Frequency",
          },
        },
        submit: {
          close: "Exit",
          failure: "Encountered an error sending your request.",
          loading: "Sending your internal transfer request...",
          success:
            "Successfully submitted internal transfer. Head over to the View/Edit Internal Transfers tab to view your internal transfer.",
          title: "Internal Transfer Request",
        },
      },
      view: {
        confirm: "Are you sure you want to cancel this transfer?",
        confirmTitle: "Cancel Internal Transfer",
        pageTitle: "View Internal Transfers",
        table: {
          accountNumber: "From",
          amount: "Amount",
          createdAt: "Created",
          destinationAccountNumber: "To",
          empty: "No Internal Transfers",
          status: "Status",
          "status.options": {
            "Cancel Requested": "Canceled",
            "Clearing Canceled": "Canceled",
            "Clearing Completed": "Complete",
            "Clearing Error": "Rejected",
            "Clearing Rejected": "Rejected",
            "Pending Submission to Clearing": "Pending",
            "Submitted to Clearing": "Pending",
          },
        },
      },
      pageTitle: "Internal Transfers",
    },
    unsubscribe: {
      note: "Please note that due to the important and time-sensitive nature of certain notifications, you cannot opt-out of emails regarding specific account updates, position or margin risk, or trade security.",
      redirect: "View Communication Preferences",
      title: "You're Unsubscribed!",
    },
    user: {
      currentPassword: "Current Password",
      passwordNew: "New Password",
    },
    w8Ben: {
      actionRequired: "Action Required",
      address: {
        title: "Home address",
      },
      alert: {
        taxFormHeader: "W-8 BEN Tax Form",
        title: "Recertify your W‒8 BEN today",
      },
      confirmationToast:
        "Your request is being processed. We'll reach out if any further action is required.",
      disclaimer: {
        header: "Disclaimer",
      },
      isSameAddress: {
        title: "My mailing address is the same as my physical address",
      },
      landing: {
        description:
          "Please confirm your address listed below is current. If it needs to be updated we ask that you edit the address on file.",
        header: "Verify your information",
        verifyButton: "Verify my information",
      },
      mailingAddress: {
        title: "Mailing address",
      },
      modal: {
        header: "Your W‒8 BEN is expiring",
      },
      pageTitle: "W-8 BEN Recertification",
      trading: {
        content: "Your W-8 BEN requires recertification for tax purposes.",
        header: "Renew your W-8 BEN",
        learnMoreLink:
          "https://support.tastytrade.com/support/s/solutions/articles/43000472762",
      },
      verifyButton: "Verify Now",
    },
    watchlist: {
      emptyPersonalWatchlistMessage: "There are no symbols in this watchlist",
    },
    watchlistComfortableCompactLayout: {
      dialog: {
        title: "Select Display Style",
        body: "Choose between two layout modes for a personalized data density experience. You can change this any time in ‘Settings’ > ‘General’.",
        save: "Save",
      },
      radioButtonItems: {
        comfortableDescription:
          "Display logos and full names of assets alongside the symbol. More breathing room between elements.",
        compactDescription:
          "Minimized space usage showing only the symbol to identify assets. More data visible on the page.",
      },
    },
    withdrawals: {
      accountOwnerOnly: "Sorry, only account owners may manage withdrawals.",
      accountRestricted:
        "You cannot perform a cash withdrawal for the account {account} at this time. Please contact tastytrade Account services",
      amount: {
        fullWithdrawal: "Full Withdrawal",
        partialWithdrawal: "Partial Withdrawal",
      },
      available: "Available to Withdraw",
      byAch: {
        navHeaders: {
          linkedAccount: "Your Bank Account",
          selectAccount: "Select an Account",
          transactionTime: "Frequency and Date",
          withdrawalAmount: "Withdrawal Amount",
        },
        sectionHeaders: {
          iraConstraint: "Verify the Type of Withdrawal You're Making",
          linkedAccount: "Your Linked Bank Account",
          selectAccount: "Select an Account to Withdraw From",
          transactionTime: "Set the Transaction Frequency",
          withdrawalAmount: "Enter Your Withdrawal Amount",
        },
        withdrawalAmount: {
          withdrawalDeadlineInfo:
            "In order to be processed the same day, ACH withdrawals must be in by 1:00 pm CT.",
          withdrawalMaxAmountInfo: `tastytrade only allows ACH transfers up to $250,000. For greater than that amount,
                please fund by wire or check.`,
        },
      },
      byCheck: {
        accountOwnersName: "Account's Owner Name:",
        checkDetails:
          "Your check will be made out to the details attached to this account:",
        "deliverymethod.options": {
          OVERNIGHT:
            "Overnight Delivery {fee, number, :: currency/USD} (Requests must be submitted by 9am CST)",
          STANDARD:
            "Standard Delivery {fee, number, :: currency/USD} (5-7 business days)",
        },
        internationalNote: "Checks not recommended for international accounts.",
        mailingAddress: "Mailing Address:",
        navHeaders: {
          selectAccount: "Select an Account",
          transactionFrequency: "Frequency and Date",
          withdrawalAmount: "Withdrawal Amount",
        },
        sectionHeaders: {
          iraConstraint: "Verify the Type of Withdrawal You're Making",
          selectAccount: "Select an Account to Withdraw From",
          transactionFrequency: "Set the Transaction Frequency",
          withdrawalAmount: "Enter Your Withdrawal Amount",
        },
        thirdPartyNote:
          "Check disbursement requests may not be made out to a third party.",
      },
      byWire: {
        amountSection: {
          international: `Wire withdrawal requests may not be made out to a third party.
            International wires incur a fee of $45.`,
          note: `Wire withdrawal requests may not be made out to a third party.
                Outbound domestic wires incur a fee of $25.`,
        },
        furtherCreditAccountNumber: {
          tooltip:
            "Please include the information related to your receiving bank account here",
        },
        iban: {
          tooltip:
            "If the IBAN exceeds 30 characters, to please utilize an International Request Form and submit to banking@tastytrade.com",
        },
        navLabels: {
          amount: "Withdrawal Amount",
          bankAccount: "Your Bank Account",
          frequency: "Transaction Frequency",
          selectAccount: "Select an Account",
        },
        sectionHeaders: {
          amount: "Enter Your Withdrawal Amount",
          bankAccount: "Enter Your Bank Account",
          frequency: "Set the Transaction Frequency",
          iraConstraint: "Verify the Type of Withdrawal You're Making",
          selectAccount: "Select an Account to Withdraw From",
        },
      },
      cancel: {
        title: "Are you sure you want to cancel this withdrawal?",
      },
      feeType: {
        "Domestic Check": "Check Fee",
        "Domestic Check Overnight": "Overnight Check Fee",
        "Domestic Wire": "Wire Fee",
        "International Check": "International Check Fee",
        "International Wire": "International Wire Fee",
        "Overnight International Check": "Overnight International Wire Fee",
      },
      iraDistributionWarning: "No valid distribution reasons for this account.",
      iraDocumentReview: {
        checkbox:
          "I confirm that the Withholding Instructions have been reviewed.",
        header:
          "Please review the Withholding Instructions provided in the IRA Distribution request form linked below and confirm by clicking the box:",
      },
      iraRulesAndConditions: {
        linkText: "Click here",
        unlinkedText: "for rules and conditions applicable to IRA withdrawals.",
      },
      notification: {
        pending:
          "Withdrawal of {amount, number, :: currency/USD} successfully submitted. Current status is Pending",
        sendFailed:
          "Your withdrawal was rejected with the following message: {reason}",
      },
      pageTitle: "Withdrawals",
      summary: {
        achRelationship: "To Linked Bank Account",
        amount: "Withdrawal Amount",
        deliveryMethod: "Delivery Method",
        federalWithholding: "Less Federal Tax Withholding",
        frequency: "Withdrawal Frequency",
        netAmount: "Net Amount Received",
        netDebitAmount: "Total",
        reason: "Type of Withdrawal",
        reasonOptions: IRA_WITHDRAWAL_REASONS,
        stateWithholding: "Less State Tax Withholding",
        title: "Confirm Your Withdrawal",
        twAccount: "Withdraw from tastytrade Account",
        withdrawalType: "Type of Withdrawal",
      },
      timeline: {
        complete: {
          name: "Arrives at your Bank Account",
          nameTooltip:
            "You can expect funds to reach your bank account 1-2 business days after processing.",
        },
        pending: {
          detail: "Evening of {date, date}",
          name: "Pending",
          nameTooltip: "When funds will depart from your tastytrade account",
        },
        submitted: {
          name: "Submitted Successfully",
        },
      },
      transferEstimate: "1-2 Business Days",
    },
  },
  errors: {
    cookiesDisabled:
      "Cookies are disabled in your browser, please enable them to proceed with login.",
    errorToast: {
      buttonLink: ".",
      buttonText: "Reload app",
      message: "Details have been sent to the development team.",
      title: "An unexpected error occurred",
    },
    igInvalidSession: {
      title: "Inactive Session",
      message:
        "Your session has been inactive and were logged out for security reasons. Please sign in again.",
      buttonText: "Sign In",
      buttonLink: "https://www.ig.com/uk/login",
    },
    initializationError: {
      title: "Something has gone wrong",
      message:
        "An unexpected error occurred during app initialization. Details have been sent to the development team.",
      refresh: "Refresh",
    },
    internalServerError:
      "An internal server error occurred, please try again later.",
    networkError:
      "Unable to contact tastytrade servers. Your account may be locked. Please call the trade desk at 312-724-7075 to unlock your account.",
    serverUnavailability:
      "Server is currently unavailable, please try again later.",
    unavailableZVA: "zoomCampaignSDdk is not available",
    unknown: "Unable to contact server, please try again.",
  },
  model: {
    AcatTransferParams: {
      deliveringAccountNumber: "Account Number",
      deliveringAccountNumberConfirm: "Confirm Account Number",
      deliveringAccountTitle: "Account Holder Name (First Name, Last Name)",
      deliveringAccountType: "Account Type",
      "deliveringAccountType.options": {
        [ACCOUNT_TYPES.INDIVIDUAL]: "Individual",
        [ACCOUNT_TYPES.TRADITIONAL_IRA]: "Traditional IRA",
        [ACCOUNT_TYPES.ROTH_IRA]: "Roth IRA",
        [ACCOUNT_TYPES.SEP_IRA]: "SEP IRA",
      },
      deliveringFirstName: "Account Holder First Name",
      deliveringLastName: "Account Holder Last Name",
      "transferType.options": {
        FULL_TRANSFER: "Transfer all assets in kind",
        PARTIAL_TRANSFER_RECEIVER: "Partial Transfer",
      },
    },
    Account: {
      investmentObjective: "Trading Objective",
      "investmentObjective.options": {
        CAPITAL_PRESERVATION: "Capital Preservation",
        "CAPITAL_PRESERVATION.description":
          "A conservative investment strategy characterized by a desire to avoid risk of loss.",
        GROWTH: "Growth",
        "GROWTH.description":
          "Investing in stocks, options, or futures with strong earnings and/or revenue growth or potential.",
        INCOME: "Income",
        "INCOME.description":
          "A strategy focused on current income rather than capital appreciation.",
        SPECULATION: "Speculation",
        "SPECULATION.description":
          "Taking larger risks, usually by frequent trading, with hope of higher than-average gain.",
      },
    },
    AccountBeneficiary: {
      beneficiaryRelationship: "Relationship",
      beneficiaryType: "Beneficiary Type",
      birthDate: "Date of Birth",
      firstName: "First Name",
      lastName: "Last Name",
      middleName: "Middle Name",
      taxNumber: "Social Security Number",
    },
    AchDeposit: {
      achRelationshipId: "ACH Relationship ID",
      amount: "Deposit Amount (USD)",
      frequency: "Transaction Frequency",
      "frequency.options": {
        "One Time": "One Time",
        Weekly: "Weekly",
        Biweekly: "Biweekly (Every two weeks)",
        Monthly: "Monthly",
      },
      iraDetail: "Deposit Type",
      startDate: "First Transfer Date",
    },
    AchRelationship: {
      bankAccountNumber: "Bank Account Number",
      bankAccountType: "Bank Account Type",
      bankRoutingNumber: "ABA Routing Number",
      hasCertified:
        "Check this box to certify that the name on the bank account matches the name of the tastytrade account.",
      nickname: "Account Nickname",
    },
    AchWithdrawal: {
      achRelationshipId: "ACH Relationship ID",
      amount: "Withdrawal Amount",
      deathDistributionReason: "Death Distribution",
      federalWithholding: "Federal Withholding Amount",
      federalWithholdingEnabled:
        "Would you like to have a portion withheld for federal tax purposes?",
      "federalWithholdingEnabled.options": BOOLEAN_OPTIONS,
      federalWithholdingType: "Withholding Units",
      "federalWithholdingType.options": WITHHOLDING_TYPES,
      frequency: "Transaction Frequency",
      "frequency.options": {
        "One Time": "One Time",
      },
      reason: "Withdrawal Type",
      "reason.options": IRA_WITHDRAWAL_REASONS,
      stateWithholding: "State Withholding Amount",
      stateWithholdingEnabled:
        "Would you like to have a portion withheld for state tax purposes?",
      "stateWithholdingEnabled.options": BOOLEAN_OPTIONS,
      stateWithholdingType: "Withholding Units",
      "stateWithholdingType.options": WITHHOLDING_TYPES,
    },
    Address: {
      city: "City",
      country: "Country",
      "country.options": COUNTRY_LABELS,
      postalCode: "Postal Code",
      stateRegion: "State",
      streetOne: "Street One",
      streetTwo: "Street Two",
    },
    AllocationValue: {
      allocationMethod: "Allocation Method",
      "allocationMethod.options": {
        [ALLOCATION_METHODS.BUYING_POWER_PERCENTAGE]:
          "Option Buying Power Percentage",
        [ALLOCATION_METHODS.CONTRACT_QUANTITY]: "Contract Quantity",
        [ALLOCATION_METHODS.DOLLAR_AMOUNT]: "Dollar Amount",
      },
    },
    Autotrade: {
      allocationMethod: {
        [ALLOCATION_METHODS.BUYING_POWER_PERCENTAGE]: "% Option BP",
        [ALLOCATION_METHODS.CONTRACT_QUANTITY]: "Contract Qty",
        [ALLOCATION_METHODS.DOLLAR_AMOUNT]: "Dollar Amount",
        [ALLOCATION_METHODS.UNIT_COUNT]: "Unit",
      },
    },
    AutotradeAllocation: {
      allocationAmount: "Amount",
      allocationMethod: "Allocation Method",
      "allocationMethod.options": {
        [ALLOCATION_METHODS.BUYING_POWER_PERCENTAGE]:
          "Option Buying Power Percentage",
        [ALLOCATION_METHODS.CONTRACT_QUANTITY]: "Contract Quantity",
        [ALLOCATION_METHODS.DOLLAR_AMOUNT]: "Dollar Amount",
      },
      autotradeOperator: "Provider",
      modelPortfolio: "Model Portfolio",
      unitCount: "Number of Units",
    },
    CheckWithdrawal: {
      amount: "Amount",
      deathDistributionReason: "Death Distribution",
      deliveryMethod: "Delivery Method",
      federalWithholding: "Federal Withholding Amount",
      federalWithholdingEnabled:
        "Would you like to have a portion withheld for federal tax purposes?",
      "federalWithholdingEnabled.options": BOOLEAN_OPTIONS,
      federalWithholdingType: "Withholding Units",
      "federalWithholdingType.options": WITHHOLDING_TYPES,
      frequency: "Frequency",
      "frequency.options": {
        "One Time": "One Time",
      },
      reason: "Withdrawal Type",
      "reason.options": IRA_WITHDRAWAL_REASONS,
      stateWithholding: "State Withholding Amount",
      stateWithholdingEnabled:
        "Would you like to have a portion withheld for state tax purposes?",
      "stateWithholdingEnabled.options": BOOLEAN_OPTIONS,
      stateWithholdingType: "Withholding Units",
      "stateWithholdingType.options": WITHHOLDING_TYPES,
    },
    CommunicationGroupPreference: {
      email: "Email Notifications",
      "email.options": TOGGLE_OPTIONS,
      pushNotification: "Push Notifications",
      "pushNotification.options": TOGGLE_OPTIONS,
    },
    Customer: {
      email: "Email Address",
      familyMemberNames: "Family Member(s)",
      firstName: "First Name",
      hasIndustryAffiliation:
        "Are you employed, registered or affiliated with a securities or futures member firm, exchange or exchange member?",
      "hasIndustryAffiliation.options": BOOLEAN_OPTIONS,
      hasInstitutionalAssets:
        "Is this account maintained for any other person (whether a natural person, corporation, partnership, trust or otherwise) with total assets of at least $50 million?",
      "hasInstitutionalAssets.options": BOOLEAN_OPTIONS,
      hasListedAffiliation:
        "Are you a director, 10% shareholder, or policy-making officer of a publicly traded company?",
      "hasListedAffiliation.options": BOOLEAN_OPTIONS,
      hasPoliticalAffiliation:
        "Is the account maintained for a current or former Politically Exposed Person or Public Official (includes U.S. and Foreign Individuals)?",
      "hasPoliticalAffiliation.options": BOOLEAN_OPTIONS,
      hasReadAdvancedTradingDocuments:
        "Have you read, received, and understood each of the documents above, and do you agree to all the provisions in each document?",
      "hasReadAdvancedTradingDocuments.options": BOOLEAN_OPTIONS,
      homePhoneNumber: "Home Phone",
      industryAffiliationFirm: "Firm or Exchange Name",
      isInvestmentAdviser:
        "Are you an investment adviser registered either with the SEC under Section 203 of the Investment Advisers Act or with a state securities commission (or any agency or office performing like functions)?",
      "isInvestmentAdviser.options": BOOLEAN_OPTIONS,
      isSameAddress: "Is your mailing address and physical address the same?",
      "isSameAddress.options": BOOLEAN_OPTIONS,
      lastName: "Last Name",
      listedAffiliationSymbol: "Company Name",
      middleName: "Middle Name",
      mobilePhoneNumber: "Mobile Phone",
      password: "Password",
      politicalOrganization: "Political Organization",
      prefixName: "Prefix",
      subjectToTaxWithholding: "Are you subject to backup withholding?",
      "subjectToTaxWithholding.options": BOOLEAN_OPTIONS,
      suffixName: "Suffix",
      taxNumber: "Social Security Number",
      workPhoneNumber: "Work Phone",
    },
    CustomerSuitability: {
      annualNetIncome: "Annual Income",
      "annualNetIncome.options": {
        0: "$0 - 25K",
        25001: "$25K - 50K",
        50001: "$50K - 100K",
        100001: "$100K - 200K",
        200001: "$200K - 300K",
        300001: "$300K - $500K",
        500001: "$500K - 1.2M",
        1200001: "$1.2M +",
      },
      coveredOptionsTradingExperience: "Covered Options and Spreads",
      "coveredOptionsTradingExperience.options": INVESTMENT_EXPERIENCE_OPTIONS,
      employerName: "Employer Name",
      employmentStatus: "Employment Status",
      "employmentStatus.options": {
        EMPLOYED: "Employed",
        RETIRED: "Retired",
        STUDENT: "Student",
        UNEMPLOYED: "Unemployed",
      },
      futuresTradingExperience: "Futures",
      "futuresTradingExperience.options": INVESTMENT_EXPERIENCE_OPTIONS,
      jobTitle: "Job Title",
      liquidNetWorth: "Liquid Net Worth",
      "liquidNetWorth.options": NET_WORTH_OPTIONS,
      maritalStatus: "Marital Status",
      "maritalStatus.options": {
        SINGLE: "Single",
        MARRIED: "Married",
        DIVORCED: "Divorced",
        WIDOWED: "Widowed",
      },
      netWorth: "Net Worth",
      "netWorth.options": NET_WORTH_OPTIONS,
      numberOfDependents: "# Dependents",
      occupation: "Occupation",
      stockTradingExperience: "Stocks",
      "stockTradingExperience.options": INVESTMENT_EXPERIENCE_OPTIONS,
      uncoveredOptionsTradingExperience: "Uncovered Options",
      "uncoveredOptionsTradingExperience.options":
        INVESTMENT_EXPERIENCE_OPTIONS,
    },
    DigitalAssetClearingAddress: {
      asset: "Cryptocurrency Chains",
      "asset.options": {
        "BTC/USD": "Bitcoin",
        "ETH/USD": "Ethereum",
      },
    },
    DigitalAssetCustomerAddress: {
      address: "Wallet Address",
      "address.placeholder": "Enter wallet address",
      asset: "Select Asset",
      "asset.options": {
        "BTC/USD": "Bitcoin",
        "ETH/USD": "Ethereum",
      },
      id: "Wallet Address",
      name: "Wallet Nickname",
      "name.placeholder": "ex. My Coinbase Wallet",
    },
    DigitalAssetDeposit: {
      status: {
        [DIGITAL_ASSET_DEPOSIT_STATUSES.APPROVED]: "Approved",
        [DIGITAL_ASSET_DEPOSIT_STATUSES.NEEDS_MANUAL_RETURN]: "Under Review",
        [DIGITAL_ASSET_DEPOSIT_STATUSES.RETURN_SUBMITTED_TO_CLEARING]:
          "Under Review",
        [DIGITAL_ASSET_DEPOSIT_STATUSES.RETURNED]: "Returned",
        [DIGITAL_ASSET_DEPOSIT_STATUSES.SETTLED]: "Settled",
        [DIGITAL_ASSET_DEPOSIT_STATUSES.UNDER_REVIEW]: "Under Review",
      },
    },
    DigitalAssetWithdrawal: {
      asset: "Cryptocurrency asset",
      "quantity.placeholder": "Enter withdrawal amount",
      status: {
        [DIGITAL_ASSET_WITHDRAWAL_STATUSES.AWAITING_EXECUTION]:
          "Awaiting Execution",
        [DIGITAL_ASSET_WITHDRAWAL_STATUSES.CLEARING_APPROVED]:
          "Pending Withdrawal",
        [DIGITAL_ASSET_WITHDRAWAL_STATUSES.CLEARING_REJECTED]: "Rejected",
        [DIGITAL_ASSET_WITHDRAWAL_STATUSES.CLEARING_SETTLED]: "Complete",
        [DIGITAL_ASSET_WITHDRAWAL_STATUSES.SUBMITTED_TO_CLEARING]:
          "Pending Withdrawal",
        [DIGITAL_ASSET_WITHDRAWAL_STATUSES.EXPIRED]: "Expired",
        [DIGITAL_ASSET_WITHDRAWAL_STATUSES.CLEARING_PROCESSED]:
          "Pending Withdrawal",
      },
    },
    EntityMigration: {
      confirmEmail: "Confirm Email",
      email: "Email Address",
      password: "Password",
      passwordConfirmation: "Confirm Password",
      securityAnswer: "Security Answer",
      securityQuestion: "Security Question",
      username: "Username",
    },
    ExternalTransaction: {
      direction: {
        INCOMING: "Deposit",
        OUTGOING: "Withdrawal",
      },
      depositStatus: {
        APPROVED: "Processing",
        CANCEL_REQUESTED: "Canceled",
        CANCELED: "Canceled",
        COMPLETE: "Complete",
        COMPLETE_RESTRICTED: "Complete",
        FAILED_AT_BANK: "Rejected",
        FROZEN: "Rejected",
        FUNDS_POSTED: "Processing",
        PENDING: "Processing",
        PENDING_BROKER_APPROVAL: "Processing",
        PENDING_PRINTING: "Processing",
        PRE_SEND: "Processing",
        REJECTED: "Rejected",
        RETURNED: "Returned",
        SEND_FAILED: "Action Required",
        SENT_TO_BANK: "Processing",
        STOP_PAYMENT: "Rejected",
        undefined: "Unknown",
        VOID: "Rejected",
      },
      status: {
        APPROVED: "Pending",
        CANCEL_REQUESTED: "Canceled",
        CANCELED: "Canceled",
        COMPLETE: "Complete",
        COMPLETE_RESTRICTED: "Complete",
        FAILED_AT_BANK: "Rejected",
        FROZEN: "Rejected",
        FUNDS_POSTED: "Pending",
        PENDING: "Pending",
        PENDING_BROKER_APPROVAL: "Pending",
        PENDING_PRINTING: "Pending",
        PRE_SEND: "Pending",
        REJECTED: "Rejected",
        RETURNED: "Returned",
        SEND_FAILED: "Rejected",
        SENT_TO_BANK: "Pending",
        STOP_PAYMENT: "Rejected",
        undefined: "Unknown",
        VOID: "Rejected",
      },
      transferMethod: {
        ACH: "ACH",
        CHECK: "Check",
        WIRE: "Wire",
      },
    },
    FuturesAgreementRecord: {
      futuresIndustryFirm: "Futures Commission Merchant or Introducing Broker",
      hasFuturesIndustryAffiliation:
        "Are you registered with the CFTC, NFA, or any futures exchange member?",
      "hasFuturesIndustryAffiliation.options": BOOLEAN_OPTIONS,
      isNfaPool: "Are you registered as a pool or pool operator with the NFA?",
      "isNfaPool.options": BOOLEAN_OPTIONS,
      isRegistered:
        "Are you an affiliated person, general partner, employee, or otherwise associated with a Futures Commission Merchant or Futures Introducing Broker?",
      "isRegistered.options": BOOLEAN_OPTIONS,
      isRegistrationExempt:
        "Are you operating pursuant to a registration exemption under the Commodities Exchange Act?",
      "isRegistrationExempt.options": BOOLEAN_OPTIONS,
      isSoliciting:
        "Are you soliciting or pooling funds for the purpose of investing in a capacity that requires NFA registration or a qualified exemption?",
      "isSoliciting.options": BOOLEAN_OPTIONS,
      registrationExemption: "Provide Exemption",
      registrationMember: "Regulatory body or exchange member",
    },
    FuturesTradingRequest: {
      riskTolerance: "Risk Tolerance",
      "riskTolerance.options": {
        HIGH: "High",
        "HIGH.description":
          "I am willing to take on maximum risk and I am financially able to tolerate significant losses, such as unlimited loss, to aggressively seek maximum returns.",
        LOW: "Low",
        "LOW.description":
          "I want to preserve my initial principal in this account with minimal risk.",
        MEDIUM: "Medium",
        "MEDIUM.description":
          "I am willing to accept some risk to my initial principal and tolerate some volatility to seek higher returns. I understand I could lose a portion of my money.",
      },
      timeHorizon: "Time Horizon",
      "timeHorizon.options": {
        AVERAGE: "Average",
        "AVERAGE.description": "4 to 7 years",
        LONGEST: "Longest",
        "LONGEST.description": "8 years or more",
        SHORT: "Short",
        "SHORT.description": "Less than 3 years",
      },
    },
    InternalCashTransferForm: {
      amount: "Transfer Amount",
      contribution: "Contribution Reason",
      distribution: "Distribution Reason",
      federalWithholding: "Federal Withholding Amount",
      federalWithholdingType: "Withholding Units",
      "federalWithholdingType.options": WITHHOLDING_TYPES,
      frequency: "Transaction Frequency",
      "frequency.options": {
        "One Time": "One Time",
      },
      hasFederalWithholding:
        "Would you like to have a portion withheld for federal tax purposes?",
      "hasFederalWithholding.options": BOOLEAN_OPTIONS,
      hasStateWithholding:
        "Would you like to have a portion withheld for state tax purposes?",
      "hasStateWithholding.options": BOOLEAN_OPTIONS,
      stateWithholding: "State Withholding Amount",
      stateWithholdingType: "Withholding Units",
      "stateWithholdingType.options": WITHHOLDING_TYPES,
    },
    IraDetail: {
      iraContribution: {
        currentYear: {
          CONVERSION: "Conversion",
          EMPLOYER: "Employer",
          RECHARACTERIZATION: "Recharacterization",
          REGULAR: "Current Year",
          ROLLOVER_60_DAY: "60 Day Rollover",
          TRANSFER: "Transfer",
        },
        previousYear: {
          CONVERSION: "Previous Year Conversion",
          RECHARACTERIZATION: "Previous Year Recharacterization",
          REGULAR: "Previous Year",
          TRANSFER: "Previous Year Transfer",
        },
      },
      iraDistribution: {
        CONVERSION: "Conversion",
        DEATH: "Death Distribution",
        EXCESS_CONTRIBUTION_REMOVAL_AFTER_TAX_DEADLINE:
          "Excess Contribution Removal After Tax Deadline",
        EXCESS_CONTRIBUTION_REMOVAL_BEFORE_TAX_DEADLINE:
          "Excess Contribution Removal Before Tax Deadline",
        NORMAL: "Normal",
        PREMATURE: "Premature",
        RECHARACTERIZATION_CURRENT_YEAR: "Current Year Recharacterization",
        RECHARACTERIZATION_PRIOR_YEAR: "Prior Year Recharacterization",
        TRANSFER: "Transfer",
      },
    },
    MicroDeposits: {
      amountOne: "Micro-Deposit 1",
      amountTwo: "Micro-Deposit 2",
    },
    PortfolioMarginQuestionnaire: {
      equityTradeSize:
        "What is a typical size of Equity Shares trade that you execute?",
      "equityTradeSize.options": {
        0: "0 - 250",
        251: "251 - 500",
        501: "501 - 1,000",
        1001: "> 1,000",
      },
      hasCalendarSpreads: "Calendar Spreads",
      "hasCalendarSpreads.options": BOOLEAN_OPTIONS,
      hasCoveredSpreads: "Covered Spreads (Covered Call, Covered Put)",
      "hasCoveredSpreads.options": BOOLEAN_OPTIONS,
      hasMultiLegSpreads: "Multi-Leg Spreads (Condors, Butterflies, Ratios)",
      "hasMultiLegSpreads.options": BOOLEAN_OPTIONS,
      hasMultiProductSpreads:
        "Multi Product Spreads (Corn vs Soybeans, Bonds vs Notes)",
      "hasMultiProductSpreads.options": BOOLEAN_OPTIONS,
      hasNakedSpreads: "Naked Spreads (Strangle, Straddle)",
      "hasNakedSpreads.options": BOOLEAN_OPTIONS,
      hasOvernightPositions: "Do you carry overnight positions?",
      "hasOvernightPositions.options": BOOLEAN_OPTIONS,
      hasPreviousPortfolioMargin: "Have you had a PM account before?",
      "hasPreviousPortfolioMargin.options": BOOLEAN_OPTIONS,
      hasTypicalMarginBalance: "Do you typically carry a margin balance?",
      "hasTypicalMarginBalance.options": BOOLEAN_OPTIONS,
      hasVerticalSpreads: "Vertical Spreads",
      "hasVerticalSpreads.options": BOOLEAN_OPTIONS,
      isDiversifyingSinglePosition:
        "Are you looking to diversify a single position?",
      "isDiversifyingSinglePosition.options": BOOLEAN_OPTIONS,
      isFlatEndOfDay: "Do you trade flat at the end of the day?",
      "isFlatEndOfDay.options": BOOLEAN_OPTIONS,
      isHighConcentrationIndividualEquity:
        "Does your portfolio typically have a high concentration in individual equity names?",
      "isHighConcentrationIndividualEquity.options": BOOLEAN_OPTIONS,
      isLongShortBias:
        "Long/Short Bias (directional trades with stock and/or options)",
      "isLongShortBias.options": BOOLEAN_OPTIONS,
      isMergerArbitrage:
        "Merger Arb (long/short target stock vs opposite side in acquirer stock)",
      "isMergerArbitrage.options": BOOLEAN_OPTIONS,
      isRiskArbitrage:
        "Risk Arb (selling one security and buying another highly correlated security)",
      "isRiskArbitrage.options": BOOLEAN_OPTIONS,
      isTradingComplexStrategies:
        "Will you be trading complex option strategies (2 or more legs)?",
      "isTradingComplexStrategies.options": BOOLEAN_OPTIONS,
      isVolatilityArbitrage:
        "Vol Arb (selling volatility in one security and hedging with long volatility in another highly correlated security)",
      "isVolatilityArbitrage.options": BOOLEAN_OPTIONS,
      marginBalanceRange:
        "Which range most accurately describes your margin balance?",
      "marginBalanceRange.options": {
        0: "< $5,000,000",
        5_000_001: "$5,000,001 - $10,000,000",
        10_000_001: "$10,000,001 - $20,000,000",
        20_000_001: "> $20,000,000",
      },
      monthlyTrades:
        "How many equity and option trades do you execute on a monthly basis?",
      "monthlyTrades.options": {
        0: "0 - 10",
        11: "11 - 50",
        51: "51 - 100",
        100: "> 100",
      },
      openingAccountBalance: "What will your opening account balance be?",
      "openingAccountBalance.options": {
        0: "0 - $250,000",
        250_001: "$250,001 - $500,000",
        500_001: "$500,001 - $1,000,000",
        1_000_001: "> $1,000,000",
      },
      optionTradeSize:
        "What is a typical size of Option Contracts trade that you execute?",
      "optionTradeSize.options": {
        0: "0 - 5",
        6: "6 - 10",
        11: "11 - 20",
        21: "> 20",
      },
      specificMarginBalance: "Specify typical balance",
    },
    PrivateDocument: {
      slug: "Type",
      "slug.options": {
        confirmation: "Securities",
        futures_statement: "Futures",
      },
    },
    SecurityQuestion: {
      answer: "Answer to your Security Question",
      customQuestion: "Type Your Security Question",
      password: "Password",
      presetQuestion: "Your Security Question",
    },
    TaxCenterDocuments: {
      taxYear: "Choose Tax Year",
    },
    TradingStatus: {
      optionsLevel: "Current Plan",
      "optionsLevel.options": {
        "Covered And Cash Secured": "Limited",
        "Defined Risk Spreads": "Basic",
        "Defined Risk Spreads Plus Naked": "IRA The Works",
        "No Restrictions": "The Works",
      },
    },
    TrustedContact: {
      birthDate: "Date of Birth",
      email: "Email Address",
      firstName: "First Name",
      isDomestic:
        "Does the trusted contact currently reside in the United States?",
      "isDomestic.options": BOOLEAN_OPTIONS,
      lastName: "Last Name",
      phoneNumber: "Phone Number",
      reminder: `Please update your profile by adding a Trusted Contact. Your trusted
        contact person should be someone tastytrade is authorized to contact
        regarding your account.`,
    },
    UpdateMobilePhone: {
      currentPassword: "Current Password",
      newMobilePhone: "New Mobile Phone",
    },
    User: {
      email: "Email",
      password: "Password",
      passwordConfirmation: "Confirm Password",
      username: "Username",
    },
    WireWithdrawal: {
      amount: "Amount",
      bankAccountNumber: "Bank Account Number",
      bankCity: "Bank City",
      bankCountry: "Bank Country",
      "bankCountry.options": COUNTRY_LABELS,
      bankName: "Bank Name",
      bankRoutingNumber: "Bank ABA Number",
      deathDistributionReason: "Death Distribution",
      federalWithholding: "Federal Withholding Amount",
      federalWithholdingEnabled:
        "Would you like to have a portion withheld for federal tax purposes?",
      "federalWithholdingEnabled.options": BOOLEAN_OPTIONS,
      federalWithholdingType: "Withholding Units",
      "federalWithholdingType.options": WITHHOLDING_TYPES,
      frequency: "Transaction Frequency",
      furtherCredit: "For Further Credit To:",
      furtherCreditAccountNumber: "Receiving Account Number",
      iban: "IBAN / Bank Account Number",
      isInternational: "Is your bank located outside of the United States?",
      "isInternational.options": BOOLEAN_OPTIONS,
      reason: "Withdrawal Type",
      "reason.options": IRA_WITHDRAWAL_REASONS,
      stateWithholding: "State Withholding Amount",
      stateWithholdingEnabled:
        "Would you like to have a portion withheld for state tax purposes?",
      "stateWithholdingEnabled.options": BOOLEAN_OPTIONS,
      stateWithholdingType: "Withholding Units",
      "stateWithholdingType.options": WITHHOLDING_TYPES,
      swiftCode: "Bank BIC Number / SWIFT Code",
    },
  },
  yup: {
    acat: {
      amount: {
        noPartialShares: "must be a whole number (no partial shares)",
      },
      accountNumberConfirm: {
        mustMatch: "account numbers must match",
      },
      accountType: {
        mustMatch:
          "delivering account type must match the tastytrade account type",
      },
      transferType: {
        holdingsIsEmpty: "select at least one asset to transfer",
      },
    },
    address: {
      postalCode: "must be a valid zip code",
    },
    beneficiary: {
      ssn: "must be a valid social security number",
    },
    common: {
      array: {
        length: "must have {length} items",
        max: "must have less than or equal to {max} items",
        min: "must have at least {min} items",
      },
      bigNumber: {
        notType: "this doesn't look like a number",
      },
      boolean: {
        isValue: "must be {value}",
      },
      date: {
        max: "must be at earlier than {max, date, medium}",
        min: "must be later than {min, date, medium}",
      },
      mixed: {
        default: "is invalid",
        defined: "must be defined",
        notNull: "is a required field",
        notOneOf: "must not be one of the following values: {values}",
        notType: "that doesn't look like a {type}",
        oneOf: "must be one of the following values: {values}",
        required: "is a required field",
      },
      number: {
        integer: "must be an integer",
        lessThan: "must be less than {less}",
        max: "must be less than or equal to {max}",
        min: "must be greater than {min}",
        moreThan: "must be greater than {more}",
        negative: "must be a negative number",
        notEqual: "must be not equal {notEqual}",
        positive: "must be a positive number",
      },
      object: {
        noUnknown: "has unspecified keys",
      },
      string: {
        email: "must be a valid email",
        length: "must be exactly {length} characters",
        lowercase: "must be a lowercase string",
        matches: "doesn't seem to be formatted correctly",
        max: "must be at most {max} characters",
        min: "must be at least {min} characters",
        trim: "must be a trimmed string",
        uppercase: "must be a upper case string",
        url: "must be a valid URL",
        uuid: "must be a valid UUID",
      },
    },
    customer: {
      suitability: {
        liquidNetWorth: {
          max: "must be less than or equal to net worth",
        },
      },
    },
    digitalAssetCustomerAddress: {
      address: {
        asset: {
          required: "Please select an asset above",
        },
        invalid: "Please enter a valid wallet address",
      },
    },
    entityMigration: {
      confirmPassword: {
        mustMatch: "Passwords must match",
      },
      emailAddress: {
        mustMatch: "Emails must match",
      },
    },
    iraDetail: {
      contribution: {
        required: "must select a contribution type",
      },
      distribution: {
        required: "must select a distribution type",
      },
    },
    login: {
      missingInputs: "Please enter your credentials",
      password: "Password",
      rememberMe: "Remember Me (today)",
      username: "Username",
    },
    twoFactorMethod: {
      oneTimePassword: "Invalid Code",
    },
    user: {
      newPassword: {
        mustNotBeTheSameAsCurrent: "Cannot be the same as current password",
      },
      passwordConfirmation: {
        mustMatch: "Passwords must match",
      },
    },
    wireWithdrawal: {
      bankAccountNumber: "must be a valid bank account number",
      bankRoutingNumber: "must be a valid bank routing number",
    },
  },
}

export default en
