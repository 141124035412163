<script context="module" lang="ts">
  const triggerContext = collectionContext()
  export const getTriggerCollection = triggerContext.getContext
</script>

<script lang="ts">
  import { useActions } from "#lib/internal/helpers"
  import { collectionContext } from "#lib/internal/helpers/collectionContext"
  import { enableGroupKeyboardNavigation } from "#lib/internal/helpers/group"
  import { cn } from "#lib/utils"
  import { getTabsRootContext } from "./TabsRoot.svelte"

  const rootCtx = getTabsRootContext()
  export const triggerCollection = triggerContext.setContext()

  let className: string | undefined | null = undefined
  export { className as class }

  $: ({ dir, orientation } = $rootCtx)

  // when list of tabs changes, ensure that each one has an attached keydown listener
  const listeners = /* @__PURE__ */ new Map()
  enableGroupKeyboardNavigation(triggerCollection, listeners, orientation, dir)
</script>

<div
  class={cn(
    "inline-flex items-center justify-center rounded bg-muted p-1 text-muted-foreground data-[orientation=horizontal]:h-10 data-[orientation=vertical]:h-fit",
    className
  )}
  role="tablist"
  data-orientation={$rootCtx.orientation}
  {...$$restProps}
  use:useActions={$$restProps.use}
>
  <slot />
</div>
