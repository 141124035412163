<script lang="ts" context="module">
  type TabsContentProps = {
    value: any
    class?: string | undefined | null
  }
</script>

<script lang="ts">
  import { useActions } from "#lib/internal/helpers"
  import { cn } from "#lib/utils"
  import { TAB_STATES } from "."
  import { getTabsRootContext } from "./TabsRoot.svelte"

  type $$Props = TabsContentProps

  let className: $$Props["class"] = undefined
  export { className as class }
  export let value: $$Props["value"]

  const rootCtx = getTabsRootContext()

  $: selected = $rootCtx.value === value
</script>

{#if selected}
  <div
    class={cn(
      "focus-visible:ring-ring mt-2 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2",
      className
    )}
    {...$$restProps}
    use:useActions={$$restProps.use}
    data-state={selected ? TAB_STATES.ACTIVE : TAB_STATES.INACTIVE}
    data-orientation={$rootCtx.orientation}
    role="tabpanel"
    tabindex="0"
  >
    <slot />
  </div>
{/if}
