import TabsContent from "./TabsContent.svelte"
import TabsList from "./TabsList.svelte"
import TabsRoot from "./TabsRoot.svelte"
import TabsTrigger from "./TabsTrigger.svelte"

export const Tabs = {
  Root: TabsRoot,
  Content: TabsContent,
  List: TabsList,
  Trigger: TabsTrigger,
}

export enum TAB_STATES {
  ACTIVE = "active",
  INACTIVE = "inactive",
}
