<script context="module" lang="ts">
  import { PopoverOption } from "./index"
  import { cva, VariantProps } from "class-variance-authority"

  export interface PopoverDropdownOption extends PopoverOption {
    label?: string
  }

  // TODO: remove eventually
  const popoverDropdownVariant = cva(
    "popover-dropdown flex w-full flex-row items-center justify-between overflow-hidden",
    {
      variants: {
        variant: {
          v1: "",
          v2: "bg-interaction-field hover:!bg-interaction-field-hovered font-small-400 h-8",
        },
      },
    }
  )
</script>

<script lang="ts">
  import { createEventDispatcher } from "svelte"
  import { isCryptocurrencyTransfers } from "/@/account-management/store/cryptocurrency"
  import { px } from "/@/util/style-dimensions.js"
  import userAnalytics from "/@/util/user-analytics"

  import PopoverDropdownArrow from "./PopoverDropdownArrow.svelte"
  import PopoverOptionsPanel from "./PopoverOptionsPanel.svelte"
  import { asyncPopover } from "./index"
  import { getColorScheme } from "/@/util/color-scheme"

  export let options: PopoverOption[]

  export let above = false
  export let maxHeight: string | null = null
  export let userAnalyticsTag: any = null
  export let value: PopoverDropdownOption | null = null
  export let disabled = false
  export let variant: VariantProps<typeof popoverDropdownVariant>["variant"] =
    "v1"

  const colorScheme = getColorScheme($isCryptocurrencyTransfers)

  let cssClass = ""
  export { cssClass as class }

  const dispatch = createEventDispatcher()

  let controller: AbortController | null = null

  $: isOpen = null !== controller

  async function open(_event: Event) {
    if (controller) return

    const { bottom, left, right, top } = rootElem.getBoundingClientRect()

    let inset: string
    let maxContentHeight: string

    if (above) {
      inset = `auto ${px(windowWidth - right)} ${px(windowHeight - top)} ${px(
        left
      )}`
      maxContentHeight = `${px(top)}`
    } else {
      inset = `${px(bottom)} ${px(windowWidth - right)} auto ${px(left)}`
      maxContentHeight = `${px(windowHeight - bottom)}`
    }

    const props = {
      childrenLeft: left > windowWidth / 2,
      inset,
      maxHeight: maxHeight ?? maxContentHeight,
      options,
    }

    controller = new AbortController()
    dispatch("open")
    const result = await asyncPopover(
      PopoverOptionsPanel,
      props,
      controller.signal
    )

    // FIXME: Hack around already-open trigger-click with a sleep. Not sure
    //        there's a good solution to this. Popover's click-outside (close:
    //        null) fires before the trigger-click, so we always think we're
    //        closed.
    await new Promise((resolve, _reject) => setTimeout(resolve, 10))

    controller = null
    dispatch("close")

    if (result) {
      value = result
      dispatch("change", result)
    }
  }

  function close() {
    controller?.abort()
    dispatch("close")
  }

  let rootElem: HTMLButtonElement
  let windowHeight: number
  let windowWidth: number
</script>

<svelte:window bind:innerHeight={windowHeight} bind:innerWidth={windowWidth} />

<button
  type="button"
  class="{popoverDropdownVariant({ variant, class: cssClass })} {isOpen
    ? 'rounded-t'
    : 'rounded'}"
  bind:this={rootElem}
  on:click={open}
  on:contextmenu|stopPropagation
  use:userAnalytics={userAnalyticsTag}
  style:background={colorScheme.DROPDOWN_BACKGROUND}
  {disabled}
>
  <slot {close} {value}>
    <span
      class="
        value-text
        mx-extra-small
        my-double-extra-small
        truncate"
    >
      {value?.label ?? "Select an option..."}
    </span>
  </slot>

  <PopoverDropdownArrow {isOpen} {above} {colorScheme} />
</button>
