<script lang="ts">
  import { onMount } from "svelte"
  import { LoadingOverlay } from "/@/control"
  import {
    APP_PAGE,
    getAndClearLoginSuccessLocation,
    IG_APP,
    initializeSession,
    isCurrentSessionValid,
    LAST_LOCATION_KEY,
    LOGIN_PAGE,
    redirectToMyIg,
    redirectWithPath,
    setupAdminMode,
  } from "/@lib/shared"

  const TASTYWORKS_TOKEN = "tastyworks-token"
  const TASTYWORKS_ACCOUNT = "tastyworks-account"

  function isSupportedBrowser() {
    return typeof ResizeObserver === "function"
  }

  function storeRedirect() {
    // we ignore /branch because this prefix is used for staging branch deploys
    // else, the logic attempts to redirect to app.html#branch/branch-name which breaks.
    // ~[JH]
    if (window.location.pathname.startsWith("/branch")) return

    // this logic is meant to maintain compatibility with links in the format:
    //    https://my.tastytrade.com/manage/summary
    // which directs post-login to
    //    https://my.tastytrade.com/app.html#/manage/summary
    //
    // (unsure if links in former format are used in production, but maintaining compatibility anyway,
    // see: https://github.com/tastyworks/web-based-2.0/pull/2707 -JH)
    let redirectUrl =
      "#" + window.location.href.substring(window.location.origin.length)
    sessionStorage.setItem(LAST_LOCATION_KEY, redirectUrl)
  }

  async function determineRedirect() {
    if (!isSupportedBrowser()) {
      return "/unsupported-browser.html"
    }

    const urlParams = new URLSearchParams(window.location.search)
    let isSessionInitialized = false

    if (urlParams.has(TASTYWORKS_TOKEN)) {
      const tastyworksToken = urlParams.get(TASTYWORKS_TOKEN)
      if (urlParams.has(TASTYWORKS_ACCOUNT)) {
        setupAdminMode(tastyworksToken, urlParams.get(TASTYWORKS_ACCOUNT))
      } else {
        initializeSession(tastyworksToken)
      }
      isSessionInitialized = true
    }

    let externalAccountId = null
    if (urlParams.has("externalAccountId")) {
      externalAccountId = `?externalAccountId=${urlParams.get(
        "externalAccountId"
      )}`
    }

    const valid = await isCurrentSessionValid()

    if (!valid) {
      storeRedirect()
      return LOGIN_PAGE
    }

    return isSessionInitialized
      ? `${APP_PAGE}${window.location.hash}${externalAccountId ?? ""}`
      : getAndClearLoginSuccessLocation()
  }

  onMount(async () => {
    try {
      const redirect = await determineRedirect()
      if (IG_APP && redirect === LOGIN_PAGE) {
        redirectToMyIg()
      } else {
        redirectWithPath(redirect)
      }
    } catch (e) {
      console.log("Failed to determine redirect", e)
      if (IG_APP) {
        redirectToMyIg()
      } else {
        redirectWithPath(LOGIN_PAGE)
      }
    }
  })
</script>

<LoadingOverlay />
