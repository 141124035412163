<script>
  /*
   * Container for RecyclerView to ensure position relative is set and flex column layout
   */

  let cssClass = ""
  export { cssClass as class }
  let cssStyle = ""
  export { cssStyle as style }
</script>

<div class="relative flex flex-auto flex-col {cssClass}" style={cssStyle}>
  <slot />
</div>
