<script lang="ts" context="module">
  type TabsTriggerProps = HTMLButtonAttributes & {
    value: unknown
    id?: string | number
    class?: string | undefined | null
  }

  type SelectEvent = {
    value: TabsTriggerProps["value"]
    target: EventTarget | null
  }
</script>

<script lang="ts">
  import { generateId, useActions, useCollection } from "#lib/internal/helpers"
  import { cn } from "#lib/utils"
  import { createEventDispatcher } from "svelte"
  import type { HTMLButtonAttributes } from "svelte/elements"
  import { TAB_STATES } from "."
  import { getTriggerCollection } from "./TabsList.svelte"
  import { getTabsRootContext } from "./TabsRoot.svelte"

  type $$Props = TabsTriggerProps
  type $$Events = {
    select: SelectEvent
  }

  let className: $$Props["class"] = undefined
  export { className as class }
  export let value: $$Props["value"]
  export let disabled: $$Props["disabled"] = false
  export let id: $$Props["id"] = generateId("tabs-trigger")

  const rootCtx = getTabsRootContext()
  const triggerCollection = getTriggerCollection()
  const dispatch = createEventDispatcher<$$Events>()

  $: selected = $rootCtx.value === value

  function activate(event: PointerEvent | FocusEvent) {
    // if event is valid type
    if (event.type === "click" || event.type === "focus") {
      // always select on click, conditionally select on focus
      if (event.type === "click" || $rootCtx.activateOn === "focus") {
        $rootCtx.value = value
        dispatch("select", { target: event.target, value })
      }
    }
  }
</script>

<button
  {id}
  class={cn(
    [
      "inline-flex",
      "items-center",
      "justify-center",
      "whitespace-nowrap",
      "rounded-sm",
      "px-3",
      "py-1.5",
      "text-sm",
      "font-medium",
      "transition-all",
      "outline-general-focused-state",
      "-outline-offset-2",
      "focus-visible:outline-2",
      "disabled:pointer-events-none",
      "disabled:opacity-50",
      "data-[state=active]:text-general-primary-text",
    ],
    className
  )}
  data-state={selected ? TAB_STATES.ACTIVE : TAB_STATES.INACTIVE}
  data-orientation={$rootCtx.orientation}
  role="tab"
  tabindex={selected ? 0 : -1}
  on:click={activate}
  on:focus={activate}
  use:useActions={$$restProps.use}
  use:useCollection={{ collection: triggerCollection }}
  {disabled}
  data-disabled={disabled || undefined}
  {...$$restProps}
>
  <slot />
</button>
